<template>
  <LKLayout>
    <div class="p-[12px] flex gap-[40px]">
      <div class="text-[#7B7A7A] text-[1.25rem] leading-[1.5rem] font-normal hover:opacity-50 cursor-pointer"
        :class="`${miActive('/employer/account/settings?type=personalData') ? '!text-[#0A5CD5] !font-medium' : ''}`"
        @click="$router.push('/employer/account/settings?type=personalData')">
        Данные комапании
      </div>
      <div class="text-[#7B7A7A] text-[1.25rem] leading-[1.5rem] font-normal hover:opacity-50 cursor-pointer"
        :class="`${miActive('/employer/account/settings?type=details') ? '!text-[#0A5CD5] !font-medium' : ''}`"
        @click="$router.push('/employer/account/settings?type=details') & sendMetrick('EMPLOYER-LK-DETAILS')">
        Реквизиты
      </div>
      <div class="text-[#7B7A7A] text-[1.25rem] leading-[1.5rem] font-normal hover:opacity-50 cursor-pointer"
        :class="`${miActive('/employer/account/settings?type=loginPasswords') ? '!text-[#0A5CD5] !font-medium' : ''}`"
        @click="$router.push('/employer/account/settings?type=loginPasswords') & sendMetrick('EMPLOYER-LK-SAFETY')">
        Безопасность
      </div>
    </div>
    <div v-if="fetched">
      <Sheet v-if="miActive('/employer/account/settings?type=personalData')"
        class="flex flex-col h-min !p-[32px] md:!rounded-[24px] gap-y-[32px]">
        <UIFileUploader v-model="employerProfileForm.avatar">
          <template #default="data">
            <div v-if="data.data.imageUrl" class="inline-flex w-full cursor-pointer" @click="trigger(data)">
              <div class="!w-[242px] !h-[91px] relative cursor-pointer overflow-hidden border rounded-[12px]"
                :class="imageFrameColor">
                <template v-if="data.data.imageUrl">
                  <img :src="data.data.imageUrl" class="w-full h-full object-cover absolute left-0 top-0">
                </template>
              </div>
              <div class="flex flex-row justify-center items-center w-[348px]">
                <div class="flex flex-row gap-x-[8px]">
                  <div class="#7B7A7A">Редактировать</div>
                  <UIIcon size="24" name="camera" color="#B7B7B7"></UIIcon>
                </div>
              </div>
            </div>
            <div v-else class="inline-flex w-full cursor-pointer" @click="trigger(data)">
              <div class="!w-[242px] !h-[91px] relative cursor-pointer overflow-hidden border rounded-[12px]"
                :class="imageFrameColor">
                <template v-if="!data.data.imageUrl">
                  <div class="absolute top-[26px] left-[105px]">
                    <UIIcon size="36" name="camera" color="#B7B7B7"></UIIcon>
                  </div>

                </template>
              </div>
              <div class="flex flex-row justify-center items-center w-[348px]">
                <div class="flex flex-col gap-y-[8px]">
                  <div class="text-[#0A5CD5] font-medium text-[0.75rem] leading-[0.9375rem] text-center">
                    Загрузите лого
                    компании
                  </div>
                  <div class="mt-[6px] text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">
                    Формат PNG, JPG.
                    Вес до 2 Mb.
                  </div>
                </div>
              </div>
              <!-- <div class="flex flex-col justify-between items-center">
                  <div class="text-[#0A5CD5] font-light text-[0.75rem] leading-[0.9375rem]">Загрузите свою
                      фотографию
                  </div>
                  <div class="mt-[6px] text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">
                      Формат PNG, JPG.
                      Вес до 2 Mb.
                  </div>
              </div> -->
            </div>


          </template>

        </UIFileUploader>
        <div class="flex flex-col gap-y-[12px]">
          <UserProfileField>
            <template #input>
              <UIInput v-model="employerProfileForm.company_name" placeholder="Название компании"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] font-medium text-[20px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
            <template #buttonSave>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="updateEmployerProfile">
                <div class="contact-action-item-text">Сохранить</div>
              </div>
            </template>
          </UserProfileField>
          <div class="inline-flex gap-x-[12px]">
            <UserProfileField>
              <template #input>
                <UIInput v-model="employerProfileForm.contact_person_first_name" placeholder="Имя менеджера"
                  inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[18px]">
                </UIInput>
              </template>
              <template #button>
                <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                  <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                  <div class="contact-action-item-text">Изменить</div>
                </div>
              </template>
              <template #buttonSave>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="updateEmployerProfile">
                <div class="contact-action-item-text">Сохранить</div>
              </div>
            </template>
            </UserProfileField>
            <UserProfileField>
              <template #input>
                <UIInput v-model="employerProfileForm.contact_person_last_name" placeholder="Фамилия менеджера"
                  inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[18px]">
                </UIInput>
              </template>
              <template #button>
                <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                  <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                  <div class="contact-action-item-text">Изменить</div>
                </div>
              </template>
              <template #buttonSave>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="updateEmployerProfile">
                <div class="contact-action-item-text">Сохранить</div>
              </div>
            </template>
            </UserProfileField>
          </div>
          <UserProfileField>
            <template #icon>
              <UIIcon size="16" name="geoAlt" color="#1F1E1E"></UIIcon>
            </template>
            <template #input>
              <!--                          <UISelect-->
              <!--                            v-model="employerProfileForm.city"-->
              <!--                            trigger-class="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]"-->
              <!--                          >-->
              <!--&lt;!&ndash;                            <template #prepend-dropdown>&ndash;&gt;-->
              <!--&lt;!&ndash;                              <span></span>&ndash;&gt;-->
              <!--&lt;!&ndash;                            </template>&ndash;&gt;-->
              <!--                            <template #append-inner>-->
              <!--                              <span></span>-->
              <!--                            </template>-->
              <!--                          </UISelect>-->
              <!--                            <UIInput v-model="employerProfileForm.city"-->
              <!--                                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">-->
              <!--                            </UIInput>-->


              <Select
                :options="references.cities.map(item => ({title: item, value: item}))"
                class="!w-[300px] "
                input-class="!h-[40px] !border-0 !outline-0 focus:!outline-1"
                placeholder="Город"
                v-model="employerProfileForm.city"
              >
                <template #chevron>
                  <span></span>
                </template>
              </Select>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
            <template #buttonSave>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="updateEmployerProfile">
                <div class="contact-action-item-text">Сохранить</div>
              </div>
            </template>
          </UserProfileField>

        </div>


        <div class="flex flex-col gap-y-[12px]">
          <UserProfileField>
            <template #icon>
              <UIIcon size="16" name="envelope" color="#0A5CD5"></UIIcon>
            </template>
            <template #input>
              <UIInput v-model="employerProfileForm.email" placeholder="Email"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
            <template #buttonSave>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="updateEmployerProfile">
                <div class="contact-action-item-text">Сохранить</div>
              </div>
            </template>
          </UserProfileField>
          <UserProfileField>
            <template #icon>
              <UIIcon size="16" name="telephone" color="#0A5CD5"></UIIcon>
            </template>
            <template #input>
              <UIInput v-model="employerProfileForm.phone" placeholder="Телефон"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
            <template #buttonSave>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="updateEmployerProfile">
                <div class="contact-action-item-text">Сохранить</div>
              </div>
            </template>
          </UserProfileField>
          <UserProfileField>
            <template #icon>
              <UIIcon size="24" name="telegram"></UIIcon>
            </template>
            <template #input>
              <UIInput v-model="employerProfileForm.telegram_contact" placeholder="Telegram"
                inputClass="!border-0 !outline-none !p-0 text-[#7B7A7A] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
            <template #buttonSave>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="updateEmployerProfile">
                <div class="contact-action-item-text">Сохранить</div>
              </div>
            </template>
          </UserProfileField>
          <UserProfileField>
            <template #icon>
              <UIIcon size="24" name="whatsApp"></UIIcon>
            </template>
            <template #input>
              <UIInput v-model="employerProfileForm.whatsapp_contact" placeholder="Whatsapp"
                inputClass="!border-0 !outline-none !p-0 text-[#7B7A7A] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
            <template #buttonSave>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="updateEmployerProfile">
                <div class="contact-action-item-text">Сохранить</div>
              </div>
            </template>
          </UserProfileField>
        </div>
        <div class="inline-flex justify-end">
          <ButtonNew v-if="!needUpdatePersonal" type="stroke-disabled" class="!w-[290px]">Сохранить
          </ButtonNew>
          <ButtonNew v-else type="stroke" @click="updateEmployerProfile" class="!w-[290px]">Сохранить
          </ButtonNew>
        </div>
      </Sheet>


      <Sheet v-else-if="miActive('/employer/account/settings?type=loginPasswords')"
        class="flex flex-col h-min !p-[32px] md:!rounded-[24px] gap-y-[20px]">
        <div class="flex flex-col h-[71px] justify-between">
          <div class="h-5">Адрес электронной почты</div>
          <UserProfileField>
            <template #icon>
              <div class="ml-1">
                <UIIcon size="16" name="envelope" color="#0A5CD5"></UIIcon>
              </div>
            </template>
            <template #input>
              <UIInput v-model="loginPasswordForm.email"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
          </UserProfileField>
        </div>
        <div class="flex flex-col h-[71px] justify-between">
          <div class="h-5">Номер телефона</div>
          <UserProfileField>
            <template #icon>
              <div class="ml-1">
                <UIIcon size="16" name="telephone" color="#0A5CD5"></UIIcon>
              </div>
            </template>
            <template #input>
              <UIInput v-model="loginPasswordForm.phone_number"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
          </UserProfileField>
        </div>
        <div class="flex flex-col h-[71px] justify-between">
          <div class="h-5">Пароль для входа в личный кабинет</div>
          <UserProfileField :borderColor="passwordsBorderColor">
            <template #icon>
              <div class="ml-1">
                <UIIcon size="16" name="eye" color="#1F1E1E"></UIIcon>
              </div>
            </template>
            <template #input>
              <UIInput v-model="loginPasswordForm.password"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
          </UserProfileField>
        </div>
        <div class="flex flex-col gap-y-[8px] justify-between">
          <div class="h-5">Повторите пароль</div>
          <UserProfileField :borderColor="passwordsBorderColor">
            <template #icon>
              <div class="ml-1">
                <UIIcon size="16" name="eye" color="#1F1E1E"></UIIcon>
              </div>
            </template>
            <template #input>
              <UIInput v-model="loginPasswordForm.repeatPassword"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>

          </UserProfileField>
          <div v-if="loginPasswordForm.password !== loginPasswordForm.repeatPassword"
            class="h-[15px] font-light text-[12px] text-[#FF3B30]">Пароли не совпадают
          </div>
        </div>
        <div class="inline-flex justify-end mt-[12px]">
          <ButtonNew v-if="!needUpdateLoginPassword" type="stroke-disabled" class="!w-[290px]">Сохранить
          </ButtonNew>
          <ButtonNew v-else type="stroke" @click="updateCredentials" class="!w-[290px]">Сохранить
          </ButtonNew>
        </div>
      </Sheet>

      <Sheet v-else-if="miActive('/employer/account/settings?type=details')" class="h-min !p-[32px] md:!rounded-[24px]">

        <DetailsForm></DetailsForm>
        <!-- Detail form: {{ formsStore.employerProfileDetails }} -->
        <div class="flex flex-row justify-end mt-[40px]">
          <!-- <div class="w-[237px]">
            <ButtonNew @click="updateDetails" v-if="formsStore.employerProfileDetails.validator.isValid"
              >Сохранить</ButtonNew>
              <div v-else >
                <ButtonNew type="stroke-disabled">Сохранить</ButtonNew>
                <div class="font-light text-[#FF3B30]">Форма не валидна</div>
              </div>
            
          </div> -->
          <div class="w-[237px]">
            <ButtonNew @click="formsStore.employerProfileDetails.validator.handleSubmit(updateDetails)">Сохранить</ButtonNew>   
          </div>
        </div>
      </Sheet>

    </div>


  </LKLayout>
</template>

<script setup>

import LKLayout from "@/layouts/LKLayout";
import ButtonNew from "@/components/ui/ButtonNew";
import Sheet from "@/components/cards/Sheet";
import UIIcon from "@/components/ui/UIIcon";
import UIFileUploader from "@/components/ui/UIFileUploader";
import DetailsForm from "@/components/forms/DetailsForm.vue";
import { ref, watch, reactive, onMounted, onBeforeMount, computed } from "vue";
import UIInput from "@/components/ui/UIInput";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UserProfileField from "@/components/ui/UserProfileField.vue";
import Select from "@/components/ui/Select.vue";
import { useFetch } from "@/composables/useFetch";
import { useToast } from "vue-toast-notification";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import UISelect from "@/components/ui/UISelect";
import { useFormValidator } from "@/composables/useFormValidator";
import { useFormsStore } from "@/stores/formsStore";
import { useValidateFuncs } from "@/composables/useValidateFuncs";
import {useReferencesStore} from "@/stores/referencesStore";
import { sendMetrick } from "@/composables/utils";
const validatorFuncs = useValidateFuncs();

const toast = useToast();

const route = useRoute()
const authStore = useAuthStore()
const router = useRouter()
const fetch = useFetch()
const validateFuncs = useValidateFuncs()
const references = useReferencesStore()

const trigger = (data) => {
  data.data.triggerFileInput()
  // data.triggerFileInput
}

const employerProfile = ref(null)



// const formFV = reactive({
//   bankName: '',
// })
//
//
// const formFVRules = {
//   bankName: [isNotEmpty]
// }
//
// const formValidation = useFormValidator(
//   formFV,
//   formFVRules,
//   false
// )


// const detailsForm = reactive({
//     type: null,
//     company_name: null,
//     contact_person_first_name: null,
//     contact_person_last_name: null,
//     phone: null,
//     email: null,
//     legal_address: null,
//     fact_address: null,
//     tin: null,
//     ogrn: null,
//     current_account: null,
//     correspondent_account: null,
//     kpp: null,
//     bik: null,
//     bank_name: null
// })
// const detailsForm = ref({})
// const detailsForm = reactive({
//   bank_name: null,
// })



const formsStore = useFormsStore()

formsStore.employerProfileDetails.validator = useFormValidator(
  formsStore.employerProfileDetails.form,
  {
    bank_name: [validateFuncs.isNotEmpty],
    bik: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
    kpp: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
    correspondent_account: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
    current_account: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
    ogrn: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
    tin: [validateFuncs.isNotEmpty, validateFuncs.isValidNumber],
    fact_address: [validateFuncs.isNotEmpty],
    legal_address: [validateFuncs.isNotEmpty],
    email: [validateFuncs.isNotEmpty, validateFuncs.isValidEmail],
    phone: [validateFuncs.isNotEmpty, validateFuncs.isValidPhoneNumber],
    contact_person_first_name: [validateFuncs.isNotEmpty],
    contact_person_last_name: [validateFuncs.isNotEmpty],
    company_name: [validateFuncs.isNotEmpty]
  },
)



const employerProfileForm = reactive({
  avatar: null,
  company_name: null,
  contact_person_first_name: null,
  contact_person_last_name: null,
  city: null,
  phone: null,
  email: null,
  telegram_contact: null,
  whatsapp_contact: null
})


// const employerProfileFormValidator = useFormValidator(
//     employerProfileForm,
//     {
//         'first_name': [validatorFuncs.isNotEmpty, ],
//         'last_name': [validatorFuncs.isNotEmpty],
//         'company_name': [validatorFuncs.isNotEmpty],
//         'city': [validatorFuncs.isNotEmpty, validatorFuncs.isValidCity],
//         'email': [validatorFuncs.isNotEmpty, validatorFuncs.isValidEmail, validatorFuncs.isEmailAvailable],
//         'phone_number': [validatorFuncs.isNotEmpty, validatorFuncs.isValidPhoneNumber],
//         'password': [validatorFuncs.isNotEmpty, (value) => validatorFuncs.isMinimalLen(value, 6), (value) => validatorFuncs.isEqual(value, registrationForm.repeat_password)],
//         'repeat_password': [validatorFuncs.isNotEmpty, (value) => validatorFuncs.isMinimalLen(value, 6), (value) => validatorFuncs.isEqual(value, registrationForm.password)]
//     }
// )

const loginPasswordForm = reactive({
  email: null,
  phone_number: null,
  password: '',
  repeatPassword: ''
})

const fetched = ref(false)

const miActive = (path) => {
  if (route.fullPath.includes(path)) {
    return true
  }
  return false
}

const updateEmployerProfile = () => {
  fetch.authRequest(`account/employer/`, {
    method: 'PATCH',
    body: {
      ...employerProfileForm
    }
  }).then(response => {
    toast.success('Сохранено')
    initialize()
    authStore.getMe(true)
  })
}

const updateDetails = () => {

  fetch.authRequest(`account/employer/`, {
    method: 'PATCH',
    body: {
      ...formsStore.employerProfileDetails.form
    }
  }).then(response => {
    toast.success('Сохранено')
    initialize()
  })
}

const updateCredentials = () => {
  let responseBody = {
    email: loginPasswordForm.email,
    phone_number: loginPasswordForm.phone_number
  }
  if (loginPasswordForm.password !== '')
    responseBody.password = loginPasswordForm.password
  fetch.authRequest(`/set_credentials/`, {
    method: 'PUT',
    body: {
      ...responseBody
    }
  }).then(response => {
    toast.success('Ожидает подтверждения. Подтвердите изменения по ссылке в письме')
    loginPasswordForm.password = ''
    loginPasswordForm.repeatPassword = ''
    // initialize()
  })
}


const imageFrameColor = computed(() => {
  if (employerProfile.value) {
    if (employerProfile.value.avatar) {
      return 'border-[#0A5CD5]'
    } else {
      return 'border-[#7B7A7A]'
    }
  } else {
    return 'border-[#7B7A7A]'
  }
});

const passwordsBorderColor = computed(() => {
  if (loginPasswordForm.password === loginPasswordForm.repeatPassword)
    return '#7B7A7A'
  else
    return '#FF3B30'
});

const needUpdateLoginPassword = computed(() => {
  // if (loginPasswordForm.password === '' && loginPasswordForm.repeatPassword === '') {
  //     if (loginPasswordForm.email !== authStore.me.email || loginPasswordForm.phone_number !== authStore.me.phone_number)
  //         return true
  //     else
  //         return false
  // } else {

  // }
  if (loginPasswordForm.password !== loginPasswordForm.repeatPassword)
    return false

  if (loginPasswordForm.email !== authStore.me.email || loginPasswordForm.phone_number !== authStore.me.phone_number || loginPasswordForm.password !== '')
    return true
  else
    return false
})

const needUpdatePersonal = computed(() => {
  if (employerProfileForm.avatar !== employerProfile.value.avatar)
    return true
  if (employerProfileForm.company_name !== employerProfile.value.company_name)
    return true
  if (employerProfileForm.contact_person_first_name !== employerProfile.value.contact_person_first_name)
    return true
  if (employerProfileForm.contact_person_last_name !== employerProfile.value.contact_person_last_name)
    return true
  if (employerProfileForm.city !== employerProfile.value.city)
    return true
  if (employerProfileForm.phone !== employerProfile.value.phone)
    return true
  if (employerProfileForm.email !== employerProfile.value.email)
    return true
  if (employerProfileForm.telegram_contact !== employerProfile.value.telegram_contact)
    return true
  if (employerProfileForm.whatsapp_contact !== employerProfile.value.whatsapp_contact)
    return true
});

onBeforeMount(() => {
  initialize()
  references.getCities()

})

const type = computed(() => {
  return route.query.type
})

watch(() => type.value, (v) => {

  if (!v) {
    router.push({
      path: route.path,
      query: {
        ...route.query,
        type: 'personalData'
      }
    })
    return
  }
})

const initialize = () => {
  if (!type.value) {
    router.push({
      path: route.path,
      query: {
        ...route.query,
        type: 'personalData'
      }
    })
  }
  const meRequest = authStore.getMe(true);
  Promise.all([meRequest]).then(() => {

      employerProfile.value = authStore.me.employer_profile

      employerProfileForm.avatar = employerProfile.value.avatar
      employerProfileForm.company_name = employerProfile.value.company_name
      employerProfileForm.contact_person_first_name = employerProfile.value.contact_person_first_name
      employerProfileForm.contact_person_last_name = employerProfile.value.contact_person_last_name
      employerProfileForm.city = employerProfile.value.city
      employerProfileForm.phone = employerProfile.value.phone
      employerProfileForm.email = employerProfile.value.email
      employerProfileForm.telegram_contact = employerProfile.value.telegram_contact
      employerProfileForm.whatsapp_contact = employerProfile.value.whatsapp_contact

      loginPasswordForm.email = authStore.me.email
      loginPasswordForm.phone_number = authStore.me.phone_number

      // detailsForm.value.company_name = employerProfile.value.company_name
      formsStore.employerProfileDetails.form.company_name = employerProfile.value.company_name
      formsStore.employerProfileDetails.form.type = employerProfile.value.type
      formsStore.employerProfileDetails.form.contact_person_first_name = employerProfile.value.contact_person_first_name
      formsStore.employerProfileDetails.form.contact_person_last_name = employerProfile.value.contact_person_last_name
      formsStore.employerProfileDetails.form.phone = employerProfile.value.phone
      formsStore.employerProfileDetails.form.email = employerProfile.value.email
      formsStore.employerProfileDetails.form.legal_address = employerProfile.value.legal_address
      formsStore.employerProfileDetails.form.fact_address = employerProfile.value.fact_address
      formsStore.employerProfileDetails.form.tin = employerProfile.value.tin
      formsStore.employerProfileDetails.form.ogrn = employerProfile.value.ogrn
      formsStore.employerProfileDetails.form.current_account = employerProfile.value.current_account
      formsStore.employerProfileDetails.form.correspondent_account = employerProfile.value.correspondent_account
      formsStore.employerProfileDetails.form.kpp = employerProfile.value.kpp
      formsStore.employerProfileDetails.form.bik = employerProfile.value.bik
      formsStore.employerProfileDetails.form.bank_name = employerProfile.value.bank_name
      // detailsForm.value.company_name = employerProfile.value.company_name
      // detailsForm.value.type = employerProfile.value.type
      fetched.value = true
  });
}

</script>

<style scoped>
.empty-photo-bg {
  background-color: rgba(10, 92, 213, 0.05);
}

.name {
  color: #0A5CD5;
  /* Цвет текста */
  font-weight: 500;
  /* Средний вес шрифта (font-medium) */
  font-size: 20px;
  /* Размер шрифта */
  height: 48px;
  /* Высота */
  padding-left: 8px;
  /* Отступ слева */
  padding-right: 8px;
  /* Отступ справа */
  padding-top: 12px;
  /* Отступ сверху */
  padding-bottom: 12px;
  /* Отступ снизу */
}

.user-profile-field-button {

  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  /* Blue */
  color: #0A5CD5;

  white-space: nowrap;
}

.contact-action-item-text {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0A5CD5;
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>