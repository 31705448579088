<template>
  <div class="flex flex-col" >
    <Sheet
      class="p-[16px_8px] md:p-[56px] md:!w-[993px]"
      v-if="resume"
    >
      <div >
        <div class="flex gap-[33px]  justify-between">
          <div class="flex flex-col gap-[9px] md:w-[426px]">
            <div v-if="$authStore.isAuthenticated" class="hidden md:flex text-black font-medium text-[1.875rem] leading-[2.3125rem] whitespace-nowrap items-center gap-[32px]">Общая информация

              <div class="hidden md:flex text-xs md:text-[0.75rem] leading-3 md:leading-[0.9375rem] text-gray-400 md:text-[#B7B7B7] md:font-light md:mt-[8px] w-[134px]">
                Опубликовано {{ utils.emptySC(publicationTime, formatDateTimeResumeCreated(publicationTime)) }}
              </div>
            </div>
            <div v-if="$authStore.isAuthenticated" class="md:mt-[31px] font-bold text-lg md:text-[2.25rem] leading-6 md:leading-[2.75rem] text-black md:text-[#0A5CD5] flex-none order-0 self-stretch flex-grow-0">
              {{ utils.emptySC(employeeLastName) }} {{ utils.emptySC(employeeFirstName) }}
            </div>
            <div class="font-bold text-[1.125rem] md:text-[1.875rem] leading-[1.375rem] md:leading-[2.3125rem] text-[#0A5CD5] md:text-black"
            >{{ utils.emptySC(specialityName) }}
            </div>
<!--           class="flex-none order-2 flex-grow-0" -->
            <div class="font-bold text-[0.875rem] md:text-[1.5rem]  leading-[1.0625rem] md:leading-[1.5rem] text-black">{{ utils.emptySC(city) }}</div>

            <div class="hidden md:flex flex-col">
              <div class="mt-[16px] flex justify-between">
                <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Гражданство</div>
                <div class="text-black text-[0.875rem] leading-[1.0625rem]">{{ utils.emptySC(citizenship) }}</div>
              </div>

              <div  class="mt-[16px] flex justify-between">
                <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Полных лет</div>
                <div class="text-black font-medium text-[0.875rem] leading-[1.0625rem]">{{ utils.emptySC(age, formatAge(age))  }}</div>
              </div>


<!--              <div class="mt-[16px] flex justify-between">-->
<!--                <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]"></div>-->
<!--                <div class="text-black text-[0.875rem] leading-[1.0625rem]">{{ '01.05.1970' }}</div>-->
<!--              </div>-->

              <div class="mt-[16px] flex justify-between">
                <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Зарплатные ожидания</div>

                <div class="text-[1.125rem] md:text-2xl leading-[1.375rem] md:leading-[1.8125rem] md:font-medium text-black whitespace-nowrap">
                  {{ utils.emptySC(resume?.salary_expectations, formatPrice(resume?.salary_expectations, true)) }}
                </div>
              </div>

              <!--        <div class="mt-[16px] flex justify-between">-->
              <!--          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Семейное положение</div>-->
              <!--          <div class="text-black text-[0.875rem] leading-[1.0625rem]">{{ citizenship }}</div>-->
              <!--        </div>-->

              <div class="mt-[16px] flex justify-between">
                <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Релокация</div>
                <div class="text-black text-[0.875rem] leading-[1.0625rem]">
                  {{ resume?.ready_to_move ? 'Готов к переезду' : 'Не готов к переезду' }}
                </div>
              </div>

              <div class="mt-[16px] flex justify-between">
                <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Тип занятости</div>
                <div class="text-black text-[0.875rem] leading-[1.0625rem]">
<!--                  {{resume?.employment_type}}-->
                    {{  utils.emptySC(resume?.employment_type, resume?.employment_type.map(item => humanizeEmploymentType(item)).join(', '))  }}
                </div>
              </div>

              <div class="mt-[16px] flex justify-between">
                <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">График работы</div>
                <div class="text-black text-[0.875rem] leading-[1.0625rem]">
                  {{   utils.emptySC(resume?.employment_schedule, resume?.employment_schedule.map(item => humanizeEmploymentShedule(item)).join(', '))  }}
<!--                  {{-->

<!--                    utils.emptySC(employmentShedule, humanizeEmploymentShedule(employmentShedule))-->
<!--                  }}-->
                </div>
              </div>

              <div class="mt-[16px] flex justify-between">
                <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Стаж работы</div>
                <div class="text-black font-medium text-[0.875rem] leading-[1.0625rem]">{{
                    utils.emptySC(experience, formatAge(experience))
                  }}
                </div>
              </div>

              <div class="mt-[16px] flex justify-between">
                <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Образование</div>
                <div class="text-black text-[0.875rem] leading-[1.0625rem]">
                  {{ utils.emptySC(resume.education_type, humanizeEducationLevel(resume.education_type))}}
                </div>
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <img :src="imageSrcId ? imageSrc : '/images/UserEmpty.webp' " alt="Аватар"
                 class="w-[134px] md:w-[231px] h-full max-h-[153px] md:max-h-[unset] md:h-[308px] rounded-[12px]"
                 :class="$authStore.isAuthenticated ? {} : {'filter': true, 'blur-[4px]': true,}"
            />
            <div v-if="$authStore.isAuthenticated" class="hidden md:flex flex-col mt-[32px]">
              <div class="flex items-center gap-[12px] px-[10px]">
                <img src="/icons/envelope-12-blue.svg" class="w-[24px] h-[24px]">
                <div v-if="props?.contacts?.email"
                     class="flex items-center h-[33px] text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">
                  {{ props.contacts.email }}
                </div>
              </div>
              <div class="flex items-center gap-[12px] px-[10px]">
                <img src="/icons/telephone-12-blue.svg" class="w-[24px] h-[24px]">
                <div v-if="props?.contacts?.phone"
                     class="flex items-center h-[33px] text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">
                  {{ props.contacts.phone }}
                </div>
              </div>
              <div v-if="$authStore.isAuthenticated" class=" mt-[32px] flex flex-col gap-[21px] items-center">
                <slot name="under-logo">
                  <ButtonNew @click="popupsStore.openInvitationPopup(resume.id, $router)" >Пригласить</ButtonNew>
                </slot>
                
                <div class=" text-center underline text-blue-600 cursor-pointer" @click="downloadResume">Скачать PDF</div>
              </div>

            </div>

          </div>


        </div>

        <div v-if="$authStore.isAuthenticated" class="md:hidden flex gap-[39px] mt-[10px] justify-between">
          <div>
            <div class="flex items-center gap-[12px]">
              <img src="/icons/envelope-12-blue.svg">
              <div v-if="props?.contacts?.email"
                   class="flex items-center h-[33px] text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">
                {{ props.contacts.email }}
              </div>
            </div>
            <div class="flex items-center gap-[12px]">
              <img src="/icons/telephone-12-blue.svg">
              <div v-if="props?.contacts?.phone"
                   class="flex items-center h-[33px] text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">
                {{ props.contacts.phone }}
              </div>
            </div>
          </div>
          <div class="md:hidden text-xs leading-3 text-gray-400 w-[134px]">
            {{ formatDateTimeResumeCreated(publicationTime) }}
          </div>
        </div>
      </div>

      <div v-if="!$authStore.isAuthenticated" class="md:hidden">
        <div class="flex gap-[33px]">
          <div class="flex flex-col justify-between gap-[9px]">
            <div>
              <div class="font-bold text-lg leading-6 text-black flex-none order-0 self-stretch flex-grow-0">
                {{ specialityName }}
              </div>
              <div class="font-bold text-[0.875rem] leading-[1.0625rem] text-black">{{ city }}</div>
            </div>

            <div class="text-xs leading-3 text-gray-400 w-[134px]">
              {{ formatDateTimeResumeCreated(publicationTime) }}
            </div>

          </div>
          <img :src="imageSrcId ? imageSrc : '/images/UserEmpty.webp' " alt="Аватар"
               class="w-[134px] h-full max-h-[153px] filter blur-[4px] rounded-[12px]"/>

        </div>
      </div>


      <div v-if="$authStore.isAuthenticated" class="md:hidden mt-[32px] flex flex-col gap-[21px] items-center">
        <slot name="under-logo">
          <ButtonNew @click="popupsStore.openInvitationPopup(resume.id, $router)">Пригласить</ButtonNew>
        </slot>
        <div class=" text-center underline text-blue-600" @click="downloadResume">Скачать PDF</div>
      </div>
      <div v-else class="md:hidden text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] mt-[24px]">
        Зарегистрируйтесь, что бы посмотреть контакты
      </div>


      <div v-if="$authStore.isAuthenticated" class="md:hidden mt-[32px]">
        <!--        {{resume}}-->
        <div class=" text-black font-medium text-[1.125rem] leading-[1.375rem]">Общая информация</div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Гражданство</div>
          <div class="text-black text-[0.875rem] leading-[1.0625rem]">{{ citizenship }}</div>
        </div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Полных лет</div>
          <div class="text-black font-medium text-[0.875rem] leading-[1.0625rem]">{{ formatAge(age) }}</div>
        </div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Дата рождения</div>
          <div class="text-black text-[0.875rem] leading-[1.0625rem]">{{ citizenship }}</div>
        </div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Зарплатные ожидания</div>
          <div class="w-[5.1875rem] h-[1.375rem] text-[1.125rem] leading-[1.375rem] text-black whitespace-nowrap">
            {{ formatPrice(resume?.salary_expectations, true) }}
          </div>
        </div>

        <!--        <div class="mt-[16px] flex justify-between">-->
        <!--          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Семейное положение</div>-->
        <!--          <div class="text-black text-[0.875rem] leading-[1.0625rem]">{{ citizenship }}</div>-->
        <!--        </div>-->

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Релокация</div>
          <div class="text-black text-[0.875rem] leading-[1.0625rem]">
            {{ resume?.ready_to_move ? 'Готов к переезду' : 'Не готов к переезду' }}
          </div>
        </div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Тип занятости</div>
          <div class="text-black text-[0.875rem] leading-[1.0625rem]">{{ humanizeEmploymentType(employmentType) }}</div>
        </div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">График работы</div>
          <div class="text-black text-[0.875rem] leading-[1.0625rem]">{{
              humanizeEmploymentShedule(employmentShedule)
            }}
          </div>
        </div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Стаж работы</div>
          <div class="text-black font-medium text-[0.875rem] leading-[1.0625rem]">{{ formatAge(experience) }}</div>
        </div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Образование</div>
          <div class="text-black text-[0.875rem] leading-[1.0625rem]">{{ citizenship }}</div>
        </div>


      </div>
      <div v-else class="mt-[47px] md:hidden">
        <div class="flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Релокация</div>
          <div class="text-black text-[0.875rem] leading-[1.0625rem]">
            {{ resume?.ready_to_move ? 'Готов к переезду' : 'Не готов к переезду' }}
          </div>
        </div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Полных лет</div>
          <div class=" font-medium text-[0.875rem] leading-[1.0625rem] text-[#01B00A]">{{ formatAge(age) }}</div>
        </div>


        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Зарплатные ожидания</div>
          <div
            class="font-bold w-[5.1875rem] h-[1.375rem] text-[1.125rem] leading-[1.375rem] text-black whitespace-nowrap">
            {{ formatPrice(resume?.salary_expectations, true) }}
          </div>
        </div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Стаж работы</div>
          <div class="text-black font-medium text-[0.875rem] leading-[1.0625rem]">{{ formatAge(experience) }}</div>
        </div>

        <div class="mt-[16px] flex justify-between">
          <div class=" text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">Образование</div>
          <div class="text-black text-[0.875rem] leading-[1.0625rem]">{{ citizenship }}</div>
        </div>
      </div>


      <div v-if="$authStore.isAuthenticated" class="mt-8 flex flex-col gap-4">
        <div class="text-black text-[1.125rem] md:text-[1.875rem] leading-[1.375rem] md:leading-[2.3125rem] md:font-medium">Обо мне</div>
        <div class="text-gray-400 text-xs leading-[0.9375rem] p-0 m-0">{{resume.about_me ? resume.about_me : 'Кандидат еще не заполнил этот раздел'}}</div>
      </div>


      <div v-if="$authStore.isAuthenticated" class="mt-[32px]">
        <div class="text-black text-[1.125rem] md:text-[1.875rem] leading-[1.375rem] md:leading-[2.3125rem] md:font-medium">Образование</div>

        <div class="mt-[16px] md:mt-[32px] flex flex-col gap-[16px]">
          <div v-for="item in resume.education" :key="item.id" class="flex flex-col md:flex-row md:items-start gap-[12px] md:gap-[24px]">
            <div class="flex items-center gap-[24px]">
              <div class="bg-[#F0CE0E] flex-none order-0 flex-grow-0 w-[1.25rem] h-[1.25rem] rounded-3xl"></div>

              <div class="md:font-medium text-[1.125rem] md:text-[1.5rem] leading-[1.375rem] md:leading-[1.8125rem] text-[#1D1D1D] md:text-black md:min-w-[137px]">{{ item.graduation_year }}</div>
            </div>

            <div class="flex flex-col md:gap-[8px]">

              <div class="font-bold md:font-medium  text-[1rem] md:text-[1.5rem] leading-[1.25rem] text-[#1D1D1D] ">
                {{ item.institution_name }}
              </div>

              <div class="md:font-medium md:text-[1.125rem] md:leading-[1.375rem] md:text-[#1E1E1E]">
                {{ item.degree_name }}
              </div>
            </div>


          </div>
        </div>

      </div>
      <div class="mt-[32px]">
        <div class="text-black text-[1.125rem] md:text-[1.875rem] leading-[1.375rem] md:leading-[2.3125rem] md:font-medium">
          {{ $authStore.isAuthenticated ? 'Опыт работы' : 'Последнее место работы' }}
        </div>

        <div v-if="resume?.experience?.length > 0" class="mt-[16px] md:mt-[32px] flex flex-col  gap-[16px] ">
          <div v-for="item in ($authStore.isAuthenticated  ? resume.experience : [resume.experience[0]])" :key="item.id"
               class="flex flex-col md:flex-row gap-[12px] md:gap-[24px] md:items-start">
            <div class="flex items-center md:items-start  gap-[24px]  ">
              <div class="bg-[#0A5CD5] flex-none order-0 flex-grow-0 w-[1.25rem] h-[1.25rem] rounded-3xl"></div>


              <div class="text-[1.125rem] md:text-[1.5rem] leading-[1.375rem] md:leading-[1.8125rem] text-[#1D1D1D] md:text-black md:font-medium md:min-w-[137px]">
                {{ (new Date(item.start_date)).getFullYear() }} - <br v-if="!isMobile" />
                {{ item.end_date ? (new Date(item.end_date)).getFullYear() : 'по н.в.' }}
              </div>
            </div>

            <div class="flex flex-col md:gap-[8px]">

              <div class="font-bold md:font-medium  text-[1rem] md:text-[1.5rem] leading-[1.25rem] md:leading-[1.8125rem] text-[#1D1D1D] md:text-black ">
                {{ item.company_name }}
              </div>
              <div class="text-[0.875rem] md:text-[1.125rem] leading-[1.0625rem] md:leading-[1.375rem] md:font-medium text-black">
                {{ item.position_name }}
              </div>

              <div v-if="$authStore.isAuthenticated" class="text-[#1E1E1E] text-[0.875rem] md:text-base leading-[1.0625rem] md:leading-5 md:font-normal">
                {{ item.position_description }}
              </div>
            </div>

          </div>
        </div>

      </div>

      <div v-if="!$authStore.isAuthenticated" class="flex flex-col items-center text-center mt-[20px]">
        <div class="text-black text-sm leading-5">Полное резюме будет доступно после</div>
        <div class="text-black text-sm leading-5 underline cursor-pointer" @click="$router.push('/signup')">
          регистрации
        </div>
      </div>

      <div class="mt-[32px] flex flex-col gap-[21px] items-center  md:items-end">
        <slot name="down-right-card">
          <ButtonNew  class="w-full md:w-fit md:p-[14px_69px]"
          @click="popupsStore.openInvitationPopup(resume.id, $router)"
        >Пригласить</ButtonNew>
        </slot>
        
      </div>
    </Sheet>

    <div class="md:hidden mt-[40px] font-medium text-center underline text-blue-600" @click="$router.push('/employer/resumes')">
      Вернуться к поиску резюме
    </div>


  </div>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Grade from './../../components/ui/Grade.vue';
import Button from './../../components/ui/Button.vue';
import {defineProps, defineEmits, computed, onMounted, onUnmounted, ref} from 'vue';
import {
  formatPrice,
  formatDateTime,
  formatDateTimeResumeCreated,
  formatAge,
  formatDate,
  humanizeEmploymentType,
  humanizeEmploymentShedule,
  getDateYear,
  transliterate, humanizeEducationLevel
} from '@/composables/utils';
import {useFetch} from '@/composables/useFetch';
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import {useAuthStore} from '@/stores/authStore';

const route = useRoute();
const router = useRouter();

import {useToast} from "vue-toast-notification";
import ButtonNew from "@/components/ui/ButtonNew";
import {usePopupsStore} from "@/stores/popupsStore";
import {useUtils} from "@/composables/useUtils";

const toast = useToast();
const authStore = useAuthStore();

const {baseURL, anonRequest} = useFetch();

const showToTarifs = ref(false)
const contactsShowed = ref(false)
const utils = useUtils()

const hasTarrif = computed(() => {
  return (authStore.me.user_subscription) && (authStore.me.user_subscription.can_send_responses)
})
const popupsStore = usePopupsStore()

// const doInvation = () => {
//
//   if (authStore.me.user_subscription) {
//     if (authStore.me.user_subscription.can_send_responses) {
//       router.push(`/employer/do_invation?resume_id=${props.id}`)
//       return
//     }
//   }
//   toast.warning('У вас нет активного тарифа или ваш тариф не позволяет отпарвлять отклики. Оформите новый тариф.', {duration: 6000})
//   showToTarifs.value = true
// }

const showContacts = () => {
  if (authStore.me.user_subscription) {
    if (authStore.me.user_subscription.can_see_contacts) {
      contactsShowed.value = true
      return
    }
  }
  toast.warning('У вас нет активного тарифа или ваш тариф не позволяет просматривать контакты. Оформите новый тариф.', {duration: 6000})
  showToTarifs.value = true
}

const downloadResume = async () => {
  try {
    // Выполнение запроса на бэкенд
    const response = await anonRequest(`/resumes/${props.id}/generate_pdf`, {
      method: 'GET',
      responseType: 'blob' // Важно установить тип ответа
    });

    // Создаем URL для скачиваемого файла
    const url = window.URL.createObjectURL(new Blob([response]));

    // Создаем ссылку для скачивания
    const a = document.createElement('a');
    a.href = url;
    const fullName = `${props.employeeFirstName}${props.employeeLastName}`
    a.download = `resume_${transliterate(fullName)}.pdf`; // Указываем имя файла для скачивания
    document.body.appendChild(a);
    a.click();

    // Убираем ссылку после скачивания
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Ошибка при скачивании файла:', error);
  }
};

const props = defineProps({
  id: {
    type: Number,
    default: null,
  },
  resume: {
    type: Object,
    default: null,
  },
  imageSrcId: {
    type: Number,
    default: null,
  },
  publicationTime: {
    type: String,
    default: 'Текст',
  },
  employeeFirstName: {
    type: String,
    default: 'Текст',
  },
  employeeLastName: {
    type: String,
    default: 'Текст',
  },
  specialityName: {
    type: String,
    default: 'Текст',
  },
  employmentType: {
    type: String,
    default: 'Текст',
  },
  employmentShedule: {
    type: String,
    default: 'Текст',
  },
  city: {
    type: String,
    default: 'Текст',
  },
  readyToMove: {
    type: Boolean,
    default: false,
  },
  citizenship: {
    type: String,
    default: null,
  },
  experience: {
    type: String,
    default: 'Текст',
  },
  age: {
    type: String,
    default: 'Текст',
  },
  price: {
    type: String,
    default: 'Текст',
  },
  currencyName: {
    type: String,
    default: 'Текст',
  },
  about: {
    type: String,
    default: 'Кандидат пока не заполнил этот раздел',
  },
  contacts: {
    default: () => {
    }
  },
  experienceUnits: {
    type: Array,
    default: () => [],
    validator: (val) => {
      return Array.isArray(val) && val.every(item =>
        typeof item.timeFrom === 'string' && typeof item.timeTo === 'string' && typeof item.company === 'string' && typeof item.jobTitle === 'string' && typeof item.description === 'string');
    }
  },
  educationUnits: {
    type: Array,
    default: () => [],
    validator: (val) => {
      return Array.isArray(val) && val.every(item =>
        typeof item.timeEnd === 'string' && typeof item.university === 'string' && typeof item.educationTitle === 'string');
    }
  },
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  authStore.getMe(true)
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});

const imageSrc = computed(() => {
  return `${baseURL}/files/${props.imageSrcId}/download`
});

</script>

<style scoped></style>