<template>
    <div class="relative shadow-md">
        <!-- <div class="absolute inset-0 bg-opacity-20 bg-[#3D7CD1] overflow-hidden rounded-[1rem] md:rounded-[15px]"></div> -->
<!--        <img :src="welcomeImage" class="w-screen md:h-[613px] h-[13.75rem] object-cover mt-[52px] md:mt-[-56px]">-->
        <video    autoplay muted loop playsinline class="w-screen md:h-[613px] h-[13.75rem] object-cover mt-[52px] md:mt-0 pointer-events-none">
<!--          <source src="/videos/welcome-video.mp4" type="video/mp4">-->
          <source src="https://s3.timeweb.cloud/b8d0beb1-755b3062-4ede-4c36-b213-f8c6c94df1e2/waterjob/Видео без знака.mp4" type="video/mp4">
        </video>
        <div class="absolute md:h-[324px] w-full h-min md:top-[90px] top-[138px] flex flex-row justify-center">
<!--        <div class="absolute backdrop-blur-[10px] md:h-[324px] md:w-[994px]-->
<!--             w-[21.43rem] h-[5.5rem] md:top-1/2 top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2-->
<!--             rounded-[1rem] md:rounded-[15px]">-->
            <div v-if="!isMobile" class="md:w-[993px] flex-col md:mt-[115px] md:ml-3">
                <div
                    class="md:font-medium md:text-[33px] md:leading-[40px] text-[1.25rem] leading-[1.53rem] text-[#FFFFFF]">
                    ПОСТРОЙ СВОЮ КАРЬЕРУ В СФЕРЕ
                </div>
                <div
                    class="md:font-medium md:text-[33px] md:leading-[40px] text-[1.25rem] leading-[1.53rem] text-[#FFFFFF]">
                    ВОДОСНАБЖЕНИЯ И ВОДООТВЕДЕНИЯ
                </div>
                <div class="flex flex-row md:mt-[16px]">
                    <Input @keyup.enter="handleEnter" placeholder="Профессия, должность или компания" v-model="profession" inputClass=" md:!p-[16px_16px] md:!h-[40px]" ></Input>
                    <div class="flex flex-col md:ml-[20px]">
                        <ButtonNew @click="handleEnter" class="md:w-[150px] md:!h-[42px] flex-shrink-0">Найти</ButtonNew>
                        <div class="text-white cursor-pointer mt-[16px] text-end" @click="router.push('/employer/resumes') & sendMetrick('MAIN-PAGE-IM-EMPLOYER')" v-if="!isMobile">Я работодатель</div>
                    </div>

                </div>
            </div>
            <div v-else class="w-[21.43rem] flex-col">
                <div class="text-white text-[20px] leading-[24.5px]">
                    ПОСТРОЙ СВОЮ КАРЬЕРУ
                </div>
                <div class="text-white text-[20px] leading-[24.5px]">
                    В СФЕРЕ ВОДОСНАБЖЕНИЯ
                </div>
                <div class="text-white text-[20px] leading-[24.5px]">
                    И ВОДООТВЕДЕНИЯ
                </div>
            </div> 
        </div>
    </div>

</template>

<script setup>
import ButtonNew from '../ui/ButtonNew.vue';
import Icon from '../ui/Icon.vue';
import Input from './../../components/ui/Input.vue';
import { defineProps, defineEmits, computed, ref, onMounted, onUnmounted } from 'vue';
import welcomeImage from '@/assets/images/GES.webp';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { sendMetrick } from '@/composables/utils';

const route = useRoute();
const router = useRouter();

const profession = ref('')

const props = defineProps({
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

const handleEnter = () => {
    sendMetrick('MAIN-PAGE-SEARCH')
    router.push(`/employee/vacancies?profession=${profession.value}`)
};

</script>

<style scoped>
/* @media (min-width: 769px) {
    .welcomecard {
        margin-top: 100px;
    }

    .text-outline {
        text-shadow:
            -1px -1px 0 #0A5CD5,
            1px -1px 0 #0A5CD5,
            -1px 1px 0 #0A5CD5,
            1px 1px 0 #0A5CD5;
    }
} */

@media (min-width: 769px) {
    .welcomecard {
        margin-top: 100px;
    }
}

@media (max-width: 768px) {
    .welcomecard {
        margin-top: 0rem;
    }
}



</style>