<template>
    <popup @close="emit('update:modelValue', false)">
        <div>

            <div
                class="mt-[32px] md:mt-0 mb-5 font-medium text-[1.125rem] md:text-[1.5rem] leading-[1.375rem] text-center md:text-left md:leading-[1.8125rem] text-black">
                Выберите категории, по которым есть нарушения</div>
            <!-- <div class="mb-5">Категории нарушений</div> -->
            <UICheckboxList :options="basReasons" v-model="banReasonsModel">

            </UICheckboxList>
            <div class="flex flex-row justify-end">
                <ButtonNew class="!h-[34px] md:!h-[48px] !w-[150px] mt-5" type="stroke-red"
                    @click="send()">
                    <!-- <UIIcon v-if="mobile.isMobile.value" name="x" size="32" color="red"></UIIcon> -->
                    <span>Отклонить</span>
                </ButtonNew>
            </div>

        </div>
    </popup>
</template>

<script setup>

import Popup from "@/components/ui/Popup";

import { defineProps, defineEmits, ref, watch } from "vue";
import UITextArea from "@/components/ui/UITextArea";
import ButtonNew from "@/components/ui/ButtonNew";
import { usePopupsStore } from "@/stores/popupsStore";
import { useAuthStore } from "@/stores/authStore";
import UICheckboxList from "../ui/UICheckboxList.vue";

const authStore = useAuthStore()

const popupsStore = usePopupsStore()

const basReasons = [
    { title: 'Домогательство', value: 'HARASSMENT' },
    { title: 'Домогательство/угроза', value: 'HARASSMENT/THREAT' },
    { title: 'Ненависть', value: 'HATE' },
    { title: 'Ненависть/угроза', value: 'HATE/THREAT' },
    { title: 'Незаконный', value: 'ILLEGAL' },
    { title: 'Незаконный/насилие', value: 'ILLEGAL/VIOLENCE' },
    { title: 'Самоповреждение', value: 'SELF-HARM' },
    { title: 'Самоповреждение/намерение', value: 'SELF-HARM/INTENT' },
    { title: 'Самоповреждение/инструкции', value: 'SELF-HARM/INSTRUCTIONS' },
    { title: 'Сексуальный', value: 'SEXUAL' },
    { title: 'Сексуальный/несовершеннолетние', value: 'SEXUAL/MINORS' },
    { title: 'Насилие', value: 'VIOLENCE' },
    { title: 'Насилие/графическое', value: 'VIOLENCE/GRAPHIC' }
]

const banReasonsModel = ref([])

watch(banReasonsModel, (newValue) => {
  
  popupsStore.moderationBan.currentBanReasons = newValue.map(item => item.value)
}, { deep: true })

const props = defineProps({
    modelValue: {
        default: false,
    }
})

const emit = defineEmits([
    'update:modelValue'
])


const send = () => {
    if (popupsStore.moderationBan.callback) {
        popupsStore.moderationBan.callback()
        popupsStore.moderationBan.visible = false
    }
}
</script>

<style scoped></style>