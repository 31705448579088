<template>
  <DefaultLayout>


    <UIContainer class="flex flex-col justify-center items-center ">
      <!--      <popup></popup>-->

      <UIPageHeader title="Вакансии" search-placeholder="Профессия, должность или компания"
        @search="fetchVacanciesDataFiltered() & sendMetrick('EMPLOYEE-PAGE-SEARCH')" v-model:search-value="searchQuery">

        <template #back>
          <div></div>
        </template>

        <template #append>
          <div class="flex gap-[24px]">
            <div class="cursor-pointer" @click="actionSheets.openFiltersSheet(applyFiltersCallback)">
              <img src="/icons/sliders-24-blue.svg">
            </div>
            <div class="cursor-pointer" @click="actionSheets.openOrderingSheet(applyOrderingCallback)">
              <img src="/icons/arrow-down-up-24-blue.svg">
            </div>
          </div>

        </template>
      </UIPageHeader>


      <div class="mt-[20px] pt-0 ">
        <router-link v-if="!$authStore.isAuthenticated & !isMobile" to="/signup"
          class="text-[0.87rem] md:text-[16px] font-light">
          <span class="md:h-[20px] underline cursor-pointer">Зарегистрируйтесь</span>
          чтобы разблокировать больше возможностей
        </router-link>
      </div>


      <div v-if="!authStore.isAuthenticated" class="  mt-[20px]">

        <div
          class="relative flex md:flex-wrap md:flex-row flex-col md:justify-between w-full md:gap-y-[20px] gap-y-[1rem]">
          <div class="md:w-[49%] w-full" v-for="(item, index) in vacancies" :key="index">
            <VacancyCardNew @clickOnButton="handleClickOnButton" :vacancyId=item.id :vacancyName=item.name
              :priceFrom=item.price_from :priceTo=item.price_to :currencyName=item.currency_display_name :city=item.city
              :publicationTime=item.created_at :experience=item.experience :employerAvatar="item.employer_avatar">
            </VacancyCardNew>
          </div>
          <div
            class="absolute bottom-0 left-0 md:w-[994px] w-[calc(100vw-2rem)] h-[150px] shadowsdas overflow-hidden pointer-events-none"
            style="border: none;">
          </div>
        </div>
      </div>
      <div v-else class="mt-[56px]">
        <div class="flex flex-row">
          <FiltersDesktop @clearFilters="onClearFilters" v-if="!isMobile" v-model="filters"></FiltersDesktop>
          <div class="flex flex-col md:ml-[30px] w-full md:w-[670px] ">


            <div class="hidden md:flex flex-row w-full items-center gap-[32px] md:mb-[23px]">
              <div class="">Сортировать по</div>
              <!--              {{ordering}}-->
              <div class="w-[300px]">
                <UISelectNew ref="uiSelect" v-model="ordering" :options="options" triggerClass="!h-[32px]">
                  <template #dropdown>
                    <ul class="bg-white border-[1px] border-[#0A5CD5] rounded-b-[24px] shadow-lg w-full h-[220px]">
                      <li v-for="(option, index) in options" :key="index"
                        class="px-4 py-2 hover:bg-gray-100 text-[14px] h-[48px] cursor-pointer w-full flex flex-row justify-between items-center"
                        @click="selectOption(option)">
                        <div>{{ option.title }}</div>
                        <UIRadioButton :model-value="option.value === ordering" :value="true"></UIRadioButton>
                      </li>

                    </ul>


                  </template>
                </UISelectNew>
              </div>
            </div>

            <UINoData :data="vacancies.length" :loading="loading"></UINoData>


            <div class="mb-[0.62rem]" v-for="(item, index) in vacancies" :key="index">
              <VacancyCardNew @click="handleClickOnButton(item.id)" :vacancyId=item.id :vacancyName=item.name
                :priceFrom=item.price_from :priceTo=item.price_to :currencyName=item.currency_display_name
                :city=item.city :publicationTime=item.created_at :experience=item.experience :employerAvatar="item.employer_avatar">
              </VacancyCardNew>
            </div>

            <div class="flex justify-center md:block">
              <UIPagination class="mb-[50px] md:mt-[33px]" v-if="pagination.totalPages > 0"
                :total-pages="pagination.totalPages" :current-page="pagination.page"
                @page-changed="pagination.page = $event">
              </UIPagination>

            </div>
          </div>

        </div>
      </div>
      <div class="flex flex-col items-center">
        <span v-if="!authStore.isAuthenticated"
          class="md:h-[20px] text-[0.87rem] md:text-[16px] font-light text-center md:text-left mt-[1.5rem] mb-[2rem] md:mt-[20px] md:mb-[32px]">Зарегистрируйтесь
          чтобы
          разблокировать больше возможностей</span>
        <div v-if="!authStore.isAuthenticated" class="flex flex-col items-center">
          <ButtonNew @click="router.push('/signup')" class="md:w-[318px] w-[19.8rem]">Зарегистрироваться
          </ButtonNew>
          <div
            class="flex flex-row justify-between items-center w-[14.37rem] md:w-[260px] md:h-[44px] md:mt-[32px] h-[2.7rem] mt-[2rem]">
            <div class="md:text-[16px] md:leading-[19.6px] text-[0.87rem]">
              У меня уже есть аккаунт.
            </div>
            <div @click="router.push('/login')"
              class="md:text-[16px] md:leading-[19.6px] text-[1rem] font-bold text-[#0A5CD5] cursor-pointer">
              Войти
            </div>
          </div>

          <SupportCardNew></SupportCardNew>
        </div>
      </div>


      <!-- <FooterNew></FooterNew> -->
    </UIContainer>
    <!-- </div> -->
    <!--        <FooterNew></FooterNew>-->
    <!--    </div>-->
  </DefaultLayout>
</template>

<script setup>
import VacancyCardListitem from './../components/cards/VacancyCardListitem.vue';
import ResumeSearch from './../components/cards/ResumeSearch.vue'
import TestSortPopup from './../components/cards/TestSortPopup.vue'
import PopupFilterTest from './../components/cards/TestFilterPopup.vue'
import HeaderNew from '@/components/ui/HeaderNew.vue';
import { ref, reactive, watch, onMounted, onUnmounted, computed, onBeforeMount } from 'vue';
import FooterNew from '@/components/ui/FooterNew.vue';
import SupportCardNew from '@/components/cards/SupportCardNew.vue';
import Pagination from "@/components/ui/Pagination";
import Icon from './../components/ui/Icon.vue';
import FiltersDesktop from './../components/cards/FiltersDesktop.vue'
import { useFetch } from '@/composables/useFetch';
import { useRoute, useRouter } from 'vue-router';
import UINoData from "@/components/ui/UINoData";
import Input from '@/components/ui/Input.vue';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import VacancyCardNew from '@/components/cards/VacancyCardNew.vue';
import DefaultLayout from "@/layouts/DefaultLayout";
import { useAuthStore } from '@/stores/authStore';
import Select from '@/components/ui/Select.vue';
import UIPageHeader from "@/components/ui/UIPageHeader";
import UIContainer from "@/components/ui/UIContainer";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UIPagination from "@/components/ui/UIPagination";
import Popup from "@/components/ui/Popup";
import { useActionSheetsStore } from "@/stores/actionSheets";
import UISelectNew from "@/components/ui/UISelectNew";
import { sendMetrick } from '@/composables/utils';

const pagination = reactive({
  page: 1,
  limit: 10,
  totalPages: 1,
})
const options = ref([
  {
    title: 'Сначала новые',
    value: 'newest',
  },
  {
    title: 'Сначала старые',
    value: 'oldest',
  },
  {
    title: 'По убыванию зарплат',
    value: 'salaryDec',
  },
  {
    title: 'По возрастанию зарплат',
    value: 'salaryInc',
  },

])

const uiSelect = ref(null)

const selectOption = (option) => {
  ordering.value = option.value
  uiSelect.value.hideDropdown();
}

const authStore = useAuthStore()
const actionSheets = useActionSheetsStore()

const { anonRequest, authRequest, baseURL } = useFetch();
const route = useRoute();
const router = useRouter();
const vacancies = ref([]);
const loading = ref(false);
const error = ref(null);

const newFirst = ref(true);
// const itemsPerPage = ref(null)
const page = ref(1)
const totalCount = ref(1)

const searchTerm = ref(route.query.profession);
const filters = ref({
  jobTypes: [],
  cities: [],
  jobShedules: [],
  jobExperiences: [],
  price: null,
  educations: []
});


const ordering = ref('newest')
// НЕ РАБОТАЕТ!!!!!
const onClearFilters = () => {
  filters.value.jobTypes = []
  filters.value.cities = []
  filters.value.jobShedules = []
  filters.value.jobExperiences = []
  filters.value.price = null
  filters.value.educations = []
  //
  // Object.entries(filters).forEach(([key, value]) => {
  //     registrationForm[key] = value.trim()
  //     if (!isNotEmpty(value)) {
  //         registrationFormErrors[key] = 'Поле должно быть заполненно'
  //         registrationError.value = true
  //     } else {
  //         registrationFormErrors[key] = null
  //     }
  // });
}

// const offset = computed(() => {
//   return (page.value - 1) * itemsPerPage.value
// })

// const totalPages = computed(() => {
//   return Math.floor((totalCount.value - 1) / itemsPerPage.value) + 1
// })

const handleClickOnButton = (id) => {
  if (authStore.isAuthenticated) {
    router.push(`/employee/vacancies/${id}`)
  } else {
    router.push('/login')
  }

}


const applyFiltersCallback = () => {

  //  ТУТ НАДО НАПИСАТЬ КОД КОТОРЫЙ ПРИМЕНЯЕТ ФИЛЬТРЫ
  // Задать значения полей переменной filters значениями из стора actionsSheets
  filters.value.jobTypes = actionSheets.filters.filterValues.jobTypes
  filters.value.cities = actionSheets.filters.filterValues.cities
  filters.value.jobShedules = actionSheets.filters.filterValues.jobShedules
  filters.value.jobExperiences = actionSheets.filters.filterValues.jobExperiences
  filters.value.price = actionSheets.filters.filterValues.price
  filters.value.educations = actionSheets.filters.filterValues.educations

  fetchVacanciesDataFiltered();
}

const applyOrderingCallback = () => {

  ordering.value = actionSheets.ordering.value
  fetchVacanciesDataFiltered();
  //  ТУТ НАДО НАПИСАТЬ КОД КОТОРЫЙ ПРИМЕНЯЕТ СОРТИРОВКУ
  // Задать переменной ordering значение из стора actionsSheets
}


watch(newFirst, (newValue) => {
  fetchVacanciesDataFiltered();
});

watch(() => pagination.page, (newValue) => {
  fetchVacanciesDataFiltered();
});

watch(filters, (newValue) => {
  fetchVacanciesDataFiltered();
}, { deep: true });

const handleSearch = () => {
  fetchVacanciesDataFiltered();
}

const fetchVacanciesData = async (queryParams = {}) => {

  loading.value = true; // Устанавливаем статус загрузки
  vacancies.value = []
  error.value = null; // Сбрасываем предыдущую ошибку
  queryParams.limit = pagination.limit
  queryParams.offset = (pagination.page - 1) * pagination.limit

  try {
    // Создаем строку запроса вручную
    const queryArray = [];
    for (const key in queryParams) {
      const value = queryParams[key];
      // Проверьте, является ли значение массивом
      if (Array.isArray(value)) {
        // Если массив, объедините значения через запятую
        queryArray.push(`${key}=${value.join(',')}`);
      } else {
        queryArray.push(`${key}=${value}`);
      }
    }
    let queryString = queryArray.join('&');
    queryString
    const url = `/vacancies/${queryString ? '?' + queryString : ''}`;


    const response = await anonRequest(url, {
      method: 'GET',
    });

     // Логируем конечный URL

    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      vacancies.value = response.results; // Предполагаем, что API возвращает массив данных
      totalCount.value = response.count
      pagination.totalPages = Math.ceil(response.count / pagination.limit)
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

const searchQuery = ref(null)

watch(() => route.query.profession, (v) => {
  searchQuery.value = v
})

watch(() => ordering.value, () => {
  fetchVacanciesDataFiltered()
})

watch(() => searchQuery.value, (newValue, oldValue) => {
  if (oldValue && (!newValue)) {
    fetchVacanciesDataFiltered()
  }
})

const fetchVacanciesDataFiltered = () => {
  
  let jobTypes = []
  filters.value.jobTypes.forEach((element, index) => {
    if (element === true) {
      if (index === 0) {
        jobTypes.push('full_day')
      } else if (index === 1) {
        jobTypes.push('not_full_day')
      } else if (index === 2) {
        jobTypes.push('project')
      } else if (index === 3) {
        jobTypes.push('intership')
      }
    }

  })
  let jobShedules = []
  filters.value.jobShedules.forEach((element, index) => {
    if (element === true) {
      if (index === 0) {
        jobShedules.push('full_day')
      } else if (index === 1) {
        jobShedules.push('shift_work')
      } else if (index === 2) {
        jobShedules.push('remote')
      } else if (index === 3) {
        jobShedules.push('flexible')
      }
    }

  })
  let experienceDiapason = []
  filters.value.jobExperiences.forEach((element, index) => {
    if (element === true) {
      if (index === 0) {
        experienceDiapason.push('no_experience')
      } else if (index === 1) {
        experienceDiapason.push('years_1_3')
      } else if (index === 2) {
        experienceDiapason.push('years_3_6')
      } else if (index === 3) {
        experienceDiapason.push('years_more_6')
      }

    }

  })
  let educations = []
  filters.value.educations.forEach((element, index) => {
    if (element === true) {
      if (index === 0) {
        educations.push('higher')
      } else if (index === 1) {
        educations.push('secondary')
      } else if (index === 2) {
        educations.push('secondary_incomplete')
      } else if (index === 3) {
        educations.push('no_education')
      } else if (index === 4) {
        educations.push('empty')
      }
    }

  })
  let queryParams = {}
  if (newFirst.value === true) {
    queryParams.order_by = '-created_at'
  } else {
    queryParams.order_by = 'created_at'
  }
  if (ordering.value === 'newest') {
    queryParams.order_by = '-created_at'
  } else if (ordering.value === 'oldest') {
    queryParams.order_by = 'created_at'
  } else if (ordering.value === 'salaryDec') {
    queryParams.order_by = '-price_from'
  } else if (ordering.value === 'salaryInc') {
    queryParams.order_by = 'price_from'
  }

  if (jobTypes.length > 0) {
    queryParams.employment_type__in = jobTypes.join(',')
  }
  if (jobShedules.length > 0) {
    queryParams.employment_schedule__in = jobShedules.join(',')
  }
  if (experienceDiapason.length > 0) {
    queryParams.experience__in = experienceDiapason.join(',')
  }
  if (educations.length > 0) {
    queryParams.education__in = educations.join(',')
  }
  if (filters.value.cities.length > 0) {
    const filtersNoFalse = filters.value.cities.filter(item => item !== false)
    queryParams.region__in = filtersNoFalse.join(',')

  }
  if (filters.value.price) {
    queryParams.price = filters.value.price
  }
  // if (searchTerm.value && searchTerm.value.trim() !== '') {
  //     queryParams.query = searchTerm.value
  // }
  if (searchQuery.value != null) {
    if (searchQuery.value.trim() !== '') {
      queryParams.query = searchQuery.value
    }

  }
  fetchVacanciesData(queryParams)
}

const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto';
  }
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onBeforeMount(() => {
  window.addEventListener('resize', updateIsMobile);
  if (authStore.isAuthenticated) {
    pagination.limit = 10
  } else {
    pagination.limit = 6
  }
});

onMounted(() => {
  searchQuery.value = route.query.profession
  fetchVacanciesDataFiltered();

})

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});

const isChecked = ref(false)


const showPopupSortTest = ref(false);
const showPopupFilterTest = ref(false);

watch(showPopupSortTest, (newValue) => {
  if (newValue) {
    disableScroll.value = true
  } else {
    disableScroll.value = false
  }
})

watch(showPopupFilterTest, (newValue) => {
  if (newValue) {
    disableScroll.value = true
  } else {
    disableScroll.value = false
  }
})

const togglePopupSortTest = () => {
  showPopupSortTest.value = !showPopupSortTest.value;
};

const togglePopupFilterTest = () => {
  showPopupFilterTest.value = !showPopupFilterTest.value;
};

const resetPopupFilterTest = () => {
  showPopupFilterTest.value = !showPopupFilterTest.value;
  filters.value.jobTypes = []
  filters.value.cities = []
  filters.value.jobShedules = []
  filters.value.jobExperiences = []
};

const toggleNewFirst = () => {
  newFirst.value = !newFirst.value;
};


const activeTab = ref(0);

</script>

<style>
.shadowsdas {
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  border: none;
  /* Убедитесь, что границы отсутствуют */
  /* Если у вас есть padding или другие стили, добавьте их при необходимости */
}

.wj-block {
  @apply md:w-[1280px] w-full h-full md:pt-[30px] md:px-[144px] px-[1rem] pt-[2rem];
}

.page-header {
  @apply md:font-medium md:leading-[44px] md:text-[36px] md:mb-[20px] md:mt-0 mb-[1rem] mt-[2rem] font-medium leading-[1.83rem] text-[1.5rem] text-[#0A5CD5]
}
</style>