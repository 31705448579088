<template>
  <DefaultLayout>
    <div v-if="globalBlur" @click="handleGlobalBlur"
      class="absolute inset-0 bg-opacity-20 bg-[#3D7CD1] overflow-hidden z-20"></div>



    <div class="flex flex-col justify-center items-center">
      <WelcomeCardNew></WelcomeCardNew>
      <div class="block !mb-[0px]" >
        <Input style="z-index: 10;" :disabled="globalBlur" v-if="isMobile" type="lupa" v-model="profession"
               @keyup.enter="handleEnter" placeholder="Поиск"></Input>
      </div>


      <div class="md:w-full flex flex-row justify-center">
        <div class="md:w-[1280px] w-full h-full md:pt-[40px] md:px-[144px] mb-[40px] px-[1rem] pt-[2rem]">

          <div
            class="font-bold md:leading-[44px] md:text-[36px] md:mb-[40px] leading-[1.52rem] text-[1.25rem] mb-[2.2rem] text-[#0A5CD5]">
            Почему мы?
          </div>
          <div class="flex md:flex-row flex-col w-full md:gap-x-5 gap-y-[2rem]">
            <WhyWeItem name="Отраслевая специализация">
              <template #picture>
                <img :src="picture1" class="picture">
              </template>
              <template #text>
                <div class="md:mb-5 mb-[1rem]">
                  Мы предлагаем вакансии, связанные исключительно с водной отраслью.
                  Такой узкий фокус упрощает процесс поиска и повышает шансы
                  на трудоустройство.
                </div>
              </template>
            </WhyWeItem>
            <WhyWeItem name="Профессиональное развитие">
              <template #picture>
                <img :src="picture2" class="picture">
              </template>
              <template #text>
                <div class="md:mb-5 mb-[1rem]">
                  Мы организуем форумы, вебинары и семинары, которые помогают развить специалистам навыки, обмениваться
                  опытом с коллегами и расширять профессиональные контакты
                </div>
              </template>
            </WhyWeItem>
            <WhyWeItem name="Доступ к уникальным предложениям">
              <template #picture>
                <img :src="picture3" class="picture">
              </template>
              <template #text>
                <div class="md:mb-5 mb-0">
                  Многие компании в водной сфере размещают вакансии только у нас. Здесь вы сможете найти уникальные должности, недоступные на общих платформах трудоустройства
                </div>
              </template>
            </WhyWeItem>

          </div>

        </div>
      </div>

      <!-- <div v-if="noAuthPopup" class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
           style="z-index: 20;">
        <NotAuthPopup @resetGlobalBlur="handleNoAuthPopup"></NotAuthPopup>
      </div> -->

      <div class="block mt-0">

        <div
          class="md:font-bold md:leading-[44px] md:text-[36px] md:mb-[20px] md:mt-0 mb-[1rem]  font-medium leading-[1.83rem] text-[1.5rem] text-[#0A5CD5]">
          Вакансии
        </div>
        <div class="flex md:flex-wrap md:flex-row flex-col md:justify-between w-full md:gap-y-[20px] gap-y-[1rem]">
          <a class="md:w-[49%] w-full" v-for="(item, index) in vacancies" :key="index">
            <VacancyCardNew @globalBlur="handleNoAuthPopup" :vacancyId=item.id :vacancyName=item.name
              :priceFrom=item.price_from :priceTo=item.price_to :currencyName=item.currency_display_name :city=item.city
              :publicationTime=item.created_at :experience=item.experience class="md:h-[225px]">
            </VacancyCardNew>
          </a>

        </div>
      </div>


      <div class="font-bold md:leading-[44px] w-full md:w-[1280px] md:px-[144px] md:text-[36px] md:mb-[24px]
         leading-[1.52rem] text-[1.25rem] mb-[1.6rem] text-[#0A5CD5] md:pt-[30px] px-[1rem] pt-[2rem]">
        Ближайшие мероприятия
      </div>
      <div class="w-full mb-[430px] md:mb-[335px] relative">

        <div class="absolute w-full">
          <CardsSlider class="pl-[10px] md:pl-0">
            <div v-for="(event, index) in events.items" :class="[
              index === 0 ? 'md:ml-[calc((100vw-992px)/2.0)]' : '',
              index === events.items.length-1 ? 'md:mr-[calc((100vw-992px)/2.0)]' : '',
            ] ">
              <EventCard
                :name="event.name"
                :date="humanizeDate(event.date)"
                :imageSrc="`${baseURL}/files/${event.cover}/download`"
                :text="event.short_description"
                @click="$router.push(`/education/events/${event.id}`) & sendMetrick('MAIN-PAGE-EVENT-OPEN')"
              ></EventCard>
            </div>
<!--            <div class="">-->
<!--              <EventCard name="Идеальное резюме" date="20 ноября 2024" :imageSrc="event2"-->
<!--                text="Вебинар от опытных Hr-специалистов по грамотному составлению резюме."></EventCard>-->
<!--            </div>-->
<!--            <div class="">-->
<!--              <EventCard name="Ярмарка вакансий" date="15 ноября 2024 года" :imageSrc="event1"-->
<!--                text="Приглашаем всех соискателей на ярмарку вакансий, посвященную водной отрасли!"></EventCard>-->
<!--            </div>-->
<!--            <div class="">-->
<!--              <EventCard name="Идеальное резюме" date="20 ноября 2024" :imageSrc="event2"-->
<!--                text="Вебинар от опытных Hr-специалистов по грамотному составлению резюме."></EventCard>-->
<!--            </div>-->
          </CardsSlider>
        </div>

      </div>

    </div>
  </DefaultLayout>

</template>

<script setup>
import WelcomeCardNew from '@/components/cards/WelcomeCardNew.vue';
import VacancyCard from './../components/cards/VacancyCard.vue';
import PreSignupCard from './../components/cards/PreSignupCard.vue'
import Header from './../components/ui/Header.vue'
import { ref, watch, onUnmounted, onMounted, computed, onBeforeMount } from 'vue';
import CardsSlider from '@/components/ui/CardsSlider.vue';
import { useFetch,  } from '@/composables/useFetch';
import { useAuthStore } from '@/stores/authStore';
import HeaderNew from '@/components/ui/HeaderNew.vue';
import FooterNew from '@/components/ui/FooterNew.vue';
import Input from '@/components/ui/Input.vue';
import VacancyCardNew from '@/components/cards/VacancyCardNew.vue';
// import welcomeImage from '@/assets/images/GES.png';
// import whyWeImage from '@/assets/images/points.png';
// import droplet from '@/assets/images/droplet.png'
// import mortarboard from '@/assets/images/mortarboard.png'
// import personCheck from '@/assets/images/person-check.png'
import picture1 from '@/assets/images/whyWe1.webp'
import picture2 from '@/assets/images/WhyWe2.webp'
import picture3 from '@/assets/images/WhyWe3.webp'
import dropletMobile from '@/assets/images/droplet-mobile.webp'
import mortarboardMobile from '@/assets/images/mortarboard-mobile.webp'
import personCheckMobile from '@/assets/images/person-check-mobile.webp'
import event1 from '@/assets/images/event1.png';
import event2 from '@/assets/images/event2.png';
import NotAuthPopup from '@/components/cards/NotAuthPopup.vue';
import WhyWeItem from '@/components/cards/WhyWeItem.vue';
import EventCard from '@/components/cards/EventCard.vue';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout";
import {useEventsStore} from "@/stores/eventsStore";
import {formatDateTime, humanizeDate} from "@/composables/utils";
import { sendMetrick } from '@/composables/utils';


const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();

const { authRequest, anonRequest, baseURL } = useFetch();


const vacancies = ref([]);
const vacanciesIdsWithFavorite = ref({})
const vacanciesIdsWithFavoriteInitiate = ref(false)
const loading = ref(false);
const error = ref(null);

const globalBlur = ref(false)
const noAuthPopup = ref(false)

const handleGlobalBlur = () => {
  globalBlur.value = !globalBlur.value
  if (noAuthPopup.value) {
    noAuthPopup.value = false
  }
}

const handleNoAuthPopup = () => {
  noAuthPopup.value = !noAuthPopup.value
}

// const onHideMenu = () => {

//   if (!noAuthPopup.value) {
//     globalBlur.value = false
//   }
// }

// const onShowMenu = () => {
//   globalBlur.value = true
// }

const profession = ref('')

const events = useEventsStore()

const handleEnter = () => {
  router.push(`/employee/vacancies?profession=${profession.value}`)
};

const fetchVacanciesData = async (queryParams = {}) => {
  loading.value = true; // Устанавливаем статус загрузки
  error.value = null; // Сбрасываем предыдущую ошибку
  queryParams.status__in = 'PUBLISHED'

  try {
    // Создаем строку запроса вручную
    const queryArray = [];
    for (const key in queryParams) {
      const value = queryParams[key];
      // Проверьте, является ли значение массивом
      if (Array.isArray(value)) {
        // Если массив, объедините значения через запятую
        queryArray.push(`${key}=${value.join(',')}`);
      } else {
        queryArray.push(`${key}=${value}`);
      }
    }
    const queryString = queryArray.join('&'); // Объединяем параметры
    const url = `/vacancies/${queryString ? '?' + queryString : ''}`;


    const response = await anonRequest(url, {
      method: 'GET',
    });

    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      vacancies.value = response.results; // Предполагаем, что API возвращает массив данных
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

const fetchMe = async () => {
  const url = `/me/`;

  const response = await authRequest(url, {
    method: 'GET',
  });

  response.favorites_vacancies.forEach(number => {
    vacanciesIdsWithFavorite.value[number] = true;
  });

}


const fetchSetVacancies = async (vacanciesIdsToSet = []) => {
  const url = `/vacancies/set_favorite/`;
  const responseBody = {
    "vacancies_ids": vacanciesIdsToSet
  }
  const response = await authRequest(url, {
    method: 'POST',
    body: responseBody
  });

  return response

}


const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

watch(vacanciesIdsWithFavorite, (newValue, oldValue) => {

  let vacanciesIdsToSet = []
  // if (vacanciesIdsWithFavoriteInitiate.value === true) {
  Object.keys(newValue).forEach(key => {
    if (newValue[key] === true) {
      vacanciesIdsToSet.push(Number(key))
    }
  });
  fetchSetVacancies(vacanciesIdsToSet);
  // } else {
  //   vacanciesIdsWithFavoriteInitiate.value = true
  // }

}, { deep: true });

watch(disableScroll, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
});

watch(noAuthPopup, (newValue) => {
  if (newValue) {
    globalBlur.value = true;
  } else {
    globalBlur.value = false;
  }
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onBeforeMount(() => {
  events.getNears()
})


onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
  fetchVacanciesData({
    limit: '10'
  }).then(result => {
    if (authStore.isAuthenticated) {
      fetchMe();
    }

  })
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});


const showPopup = ref(false);

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
  showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest = () => {
  showPopupFilterTest.value = !showPopupFilterTest.value;
};

const tabs = [
  { name: "tab1", label: "Новые вакансии" },
  { name: "tab2", label: "Подработка" },
];

const tabsFull = [
  { name: "tab1", label: "Новые вакансии" },
  { name: "tab2", label: "Подработка" },
  { name: "tab3", label: "Без опыта" },
  { name: "tab4", label: "Отклик без резюме" },
];

const tabsComputed = computed(() => {
  if (isMobile.value === true) {
    return tabs
  } else {
    return tabsFull
  }
})

const activeTab = ref(0);

watch(activeTab, (newValue) => {
  if (newValue === 0) {
    fetchVacanciesData({
      limit: '3'
    });
  } else if (newValue === 1) {
    fetchVacanciesData({
      employment_type__in: ['not_full_day', 'project'],
      limit: '3'
    });
  } else if (newValue === 2) {
    fetchVacanciesData({
      experience__in: 'no_experience',
      limit: '3'
    });
  } else if (newValue === 3) {
    fetchVacanciesData({
      no_resume: 'true',
      limit: '3'
    });
  }
});

</script>

<style>
@media (min-width: 769px) {
  .main {
    padding-right: 5.53rem;
    padding-left: 5.53rem;
  }
}

@media (max-width: 768px) {
  .main {
    padding-right: 0rem;
    padding-left: 0rem;
  }
}

.centered-element {
  position: absolute;
  /* Абсолютное позиционирование */
  top: 50%;
  /* Сдвигаем элемент на 50% по вертикали */
  left: 50%;
  /* Сдвигаем элемент на 50% по горизонтали */
  transform: translate(-50%, -50%);
  /* Сдвигаем элемент назад на его половину */
  background-color: lightblue;
  /* Для наглядности */
  padding: 20px;
  /* Отступы внутри элемента */
  border-radius: 5px;
  /* Скругление углов */
}

.block {
  @apply md:w-[1280px] w-full h-full md:pt-[40px] md:px-[144px] mb-[64px] px-[1rem] pt-[2rem];
}

.picture {
  @apply md:w-[277px] md:h-[150px] w-[319px] !object-cover rounded-[0.8rem];
}
</style>