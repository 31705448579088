import { createRouter, createWebHistory } from 'vue-router';
import Docs from "@/pages/Docs"
import Cards from "@/pages/Cards"
import Test2 from "@/pages/Test2"
import IndexPage from "@/pages/IndexPage"
import EmployerMainPage from "@/pages/EmployerMainPage"
import EmployerResumesPage from "@/pages/EmployerResumesPage"
import EmployerResumePage from "@/pages/EmployerResumePage"
import EmployeeMainPage from "@/pages/EmployeeMainPage"
import EmployeeVacanciesPage from "@/pages/EmployeeVacanciesPage"
import EmployeeVacancyPage from "@/pages/EmployeeVacancyPage"
import LoginPage from "@/pages/LoginPage"
import RecoverPassowordPage from "@/pages/RecoverPasswordPage"
import RegistrationPage from "@/pages/RegistrationPage"
import ResetPasswordPage from "@/pages/ResetPasswordPage"
import CreateVacancyPage from "@/pages/employer/CreateVacancyPage.vue";
import CreateResumePage from "@/pages/employee/CreateResumePage.vue";
import EmployeePersonalPage from '@/pages/employee/EmployeePersonalPage.vue';
import EmployeeResumesPage from '@/pages/employee/EmployeeResumesPage.vue';
import EmployerVacanciesPage from '@/pages/employer/EmployerVacanciesPage.vue';
import ModerationVacanciesPage from '@/pages/ModerationVacanciesPage.vue';
import EmployerPersonalPage from '@/pages/employer/EmployerPersonalPage.vue';
import OLDEmployerCompanyPage from '@/pages/employer/OLDEmployerCompanyPage.vue';
import EmployerDoInvationPage from '@/pages/employer/EmployerDoInvationPage.vue';
import EmployeeFeedbackInvationsPage from '@/pages/employee/EmployeeFeedbackInvationsPage.vue';
import InvationPage from '@/pages/InvationPage.vue';
import { useAuthStore } from '@/stores/authStore';
import EmployeeDoResponsePage from '@/pages/employee/EmployeeDoResponsePage.vue';
import ResponsesPage from '@/pages/employee/EmployeFeedbackResponsesPage.vue';
import ResponsePage from '@/pages/ResponsePage.vue';
import EmployerTarifPage from '@/pages/employer/EmployerTarifPage.vue';
import EmployerSubscriptionsPage from '@/pages/employer/EmployerSubscriptionsPage.vue';
import EmployeeRegistrationPage from '@/pages/EmployeeRegistrationPage.vue';
import EmployerRegistrationPage from '@/pages/EmployerRegistrationPage.vue';
import EmployeeLKMainPage from '@/pages/employee/EmployeeLKMainPage.vue';
import AccountSupportPage from '@/pages/account/AccountSupportPage';
import EmployeeAccountSettingsPage from '@/pages/account/EmployeeAccountSettingsPage';
import EmployerResponsesPage from '@/pages/employer/EmployerResponsesPage.vue';
import EmployerInvationsPage from '@/pages/employer/EmployerInvationsPage.vue';
import EmployerLkMainPage from '@/pages/employer/EmployerLkMainPage.vue';
import EmployerAccountSettingsPage from '@/pages/account/EmployerAccountSettingsPage';
import AccountArticlesPage from '@/pages/account/AccountArticlesPage';
import EmployerAccountEventsPage from '@/pages/account/employer/EmployerAccountEventsPage';
import AccountArticleChangePage from '@/pages/account/AccountArticleChangePage';
import EducationArticlesPage from '@/pages/education/EducationArticlesPage';
import EducationArticlePage from '@/pages/education/EducationArticlePage';
import EducationEventsPage from '@/pages/education/EducationEventsPage';
import AccountantLkMain from '@/pages/accountant/AccountantLkMain';
import AccountantLkOrder from '@/pages/accountant/AccountantLkOrder.vue';
import EducationEventPage from '@/pages/education/EducationEventPage';
import EmployerAccountEventChangePage from '@/pages/account/employer/EmployerAccountEventChangePage.vue';
import ModeratorVacanciesPage from '@/pages/moderator/ModeratorVacanciesPage.vue';
import ModeratorVacancyPage from '@/pages/moderator/ModeratorVacancyPage.vue';
import ModeratorResumesPage from '@/pages/moderator/ModeratorResumesPage.vue';
import ModeratorResumePage from '@/pages/moderator/ModeratorResumePage.vue';
import ModeratorArticlesPage from '@/pages/moderator/ModeratorArticlesPage.vue';
import ModeratorArticlePage from '@/pages/moderator/ModeratorArticlePage.vue';
import ModeratorEventsPage from '@/pages/moderator/ModeratorEventsPage.vue';
import ModeratorEventPage from '@/pages/moderator/ModeratorEventPage.vue';
import ModeratorSettingsPage from '@/pages/moderator/ModeratorSettingsPage.vue';
import ModeratorUsersPage from '@/pages/moderator/ModeratorUsersPage.vue';



const routes = [
    {
        path: '/',
        component: IndexPage,
        meta: { title: 'Биржа водных вакансий' }
    },
    {
        path: '/moderator',
        children: [
            {
                path: 'vacancies',
                component: ModeratorVacanciesPage,

            },
            {
                path: 'vacancies/:id',
                component: ModeratorVacancyPage,

            },
            {
                path: 'resumes',
                component: ModeratorResumesPage,

            },
            {
                path: 'resumes/:id',
                component: ModeratorResumePage,

            },
            {
                path: 'articles',
                component: ModeratorArticlesPage,

            },
            {
                path: 'articles/:id',
                component: ModeratorArticlePage,

            },
            {
                path: 'events',
                component: ModeratorEventsPage,
            },
            {
                path: 'events/:id',
                component: ModeratorEventPage,
            },
            {
                path: 'settings',
                component: ModeratorSettingsPage,
            },
            {
                path: 'users',
                component: ModeratorUsersPage,
            },
            
        ]
    },
    {
        path: '/redirect_to_security',
        component: IndexPage,
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            authStore.getMe()
            if (authStore.role === 'employer') {
                next('/employer/account/settings?type=loginPasswords');
            } else if (authStore.role === 'employee') {
                next('/employee/account/settings?type=loginPasswords');
            } else {
                next()
            }
        },
    },
    {
        path: '/education',
        children: [
            {
                path: 'articles',
                component: EducationArticlesPage,

            },
            {
                path: 'articles/:id',
                component: EducationArticlePage,

            },
            {
                path: 'events',
                component: EducationEventsPage,

            },
            {
                path: 'events/:id',
                component: EducationEventPage,

            },

        ]
    },
    {
        path: '/account',
        children: [
            {
                path: 'articles',
                component: AccountArticlesPage,
            },
            // {
            //     path: 'articles/create',
            //     component: AccountArticleChangePage,
            // },
            {
                path: 'articles/:id',
                component: AccountArticleChangePage,
            },
            {
                path: 'employer',
                children: [
                    {
                        path: 'events',
                        component: EmployerAccountEventsPage,
                    },
                    {
                        path: 'events/:id',
                        component: EmployerAccountEventChangePage,
                    }
                ]
            }
        ]
    },
    {
        path: '/employee/account',
        children: [
            {
                path: 'support',
                component: AccountSupportPage,
            },
            {
                path: 'settings',
                component: EmployeeAccountSettingsPage,
            },
        ]
    },
    {
        path: '/employer/account',
        children: [
            {
                path: 'support',
                component: AccountSupportPage,
            },
            {
                path: 'settings',
                component: EmployerAccountSettingsPage,
            },
        ]
    },
    {
        path: '/employee/lk/resumes/create',
        name: 'CreateResumePage',
        component: CreateResumePage,
        meta: { title: 'Создание резюме' }
    },
    {
        path: '/employee/lk/main',
        name: 'EmployeeLKMainPage',
        component: EmployeeLKMainPage,
        meta: { title: 'Главная' }
    },
    {
        path: '/employer/lk/main',
        name: 'EmployerLKMainPage',
        component: EmployerLkMainPage,
        meta: { title: 'Главная' }
    },

    {
        path: '/employee/lk/resumes/:id(\\d+)',
        name: 'UpdateResumePage',
        component: CreateResumePage,
        meta: { title: 'Редактирование резюме' }
    },
    {
        path: '/employee/lk/personal',
        name: 'EmployeePersonalPage',
        component: EmployeePersonalPage,
        meta: { title: 'Личный кабинет работника' }
    },
    {
        path: '/employee/lk/resumes',
        name: 'EmployeeResumesPage',
        component: EmployeeResumesPage,
        meta: { title: 'Список резюме работника' }
    },
    {
        path: '/employee/lk/feedbacks/invations',
        name: 'EmployeeFeedbackInvationsPage',
        component: EmployeeFeedbackInvationsPage,
        meta: { title: 'Страница с приглашениями для работника' }
    },
    {
        path: '/employer/lk/feedbacks/invations',
        name: 'EmployerFeedbackInvationsPage',
        component: EmployerInvationsPage,
        meta: { title: 'Страница с приглашениями для работодателя' }
    },
    {
        path: '/employee/lk/feedbacks/responses',
        name: 'EmployeeResponsesPage',
        component: ResponsesPage,
        meta: { title: 'Страница с откликами для работника' }
    },
    {
        path: '/employer/lk/feedbacks/responses',
        name: 'EmployerResponsesPage',
        component: EmployerResponsesPage,
        meta: { title: 'Страница с откликами для работодателя' }
    },
    {
        path: '/employer/lk/feedbacks/responses/:id(\\d+)',
        name: 'ResponsePageEmployer',
        component: ResponsePage,
        meta: { title: 'ResponsePageEmployer' }
    },
    {
        path: '/employee/lk/feedbacks/responses/:id(\\d+)',
        name: 'ResponsePageEmployee',
        component: ResponsePage,
        meta: { title: 'ResponsePageEmployee' }
    },
    {
        path: '/employer/lk/personal',
        name: 'EmployerPersonalPage',
        component: EmployerPersonalPage,
        meta: { title: 'Персональные данные работодателя' }
    },
    // {
    //     path: '/employer/lk/company',
    //     name: 'EmployerCompanyPage',
    //     component: OLDEmployerCompanyPage,
    //     meta: { title: 'Данные компании работодателя' }
    // },
    {
        path: '/employer/lk/vacancies',
        name: 'EmployerVacanciesPage',
        component: EmployerVacanciesPage,
        meta: { title: 'Список вакансий работодателя' }
    },
    {
        path: '/employer/lk/vacancies/create',
        name: 'CreateVacancyPage',
        component: CreateVacancyPage,
        meta: { title: 'Создание вакансии' }
    },
    {
        path: '/employer/lk/vacancies/:id(\\d+)',
        name: 'UpdateVacancyPage',
        component: CreateVacancyPage,
        meta: { title: 'Редактирование вакансии' }
    },
    {
        path: '/employer/do_invation',
        name: 'EmployerDoInvationPage',
        component: EmployerDoInvationPage,
        meta: { title: 'Приглашения' }
    },
    {
        path: '/employee/do_response',
        name: 'EmployeeDoResponsePage',
        component: EmployeeDoResponsePage,
        meta: { title: 'Приглашения' }
    },

    {
        path: '/employer/lk/feedbacks/invations/:id(\\d+)',
        name: 'InvationPageEmployer',
        component: InvationPage,
        meta: { title: 'Приглашения' }
    },
    {
        path: '/employer/lk/tarif',
        name: 'EmployerTarifPage',
        component: EmployerTarifPage,
        meta: { title: 'Страница тарифа работодателя' }
    },
//    {
//        path: '/employer/lk/subscriptions',
//        component: EmployerSubscriptionsPage,
//        meta: { title: 'Страница подписок работодателя' }
//    },
    {
        path: '/employee/lk/feedbacks/invations/:id(\\d+)',
        name: 'InvationPageEmployee',
        component: InvationPage,
        meta: { title: 'Приглашения' }
    },
    {
        path: '/employee',
        component: EmployeeMainPage,
        meta: { title: 'Соискателям' }
    },
    {
        path: '/employee/vacancies',
        component: EmployeeVacanciesPage,
        meta: { title: 'Вакансии' }
    },
    {
        path: '/employee/vacancies/:id(\\d+)',
        component: EmployeeVacancyPage,
        meta: { title: 'Вакансия' },
        props: true
    },
    {
        path: '/employer',
        component: EmployerMainPage,
        meta: { title: 'Работодателям' }
    },
    {
        path: '/employer/resumes',
        component: EmployerResumesPage,
        meta: { title: 'Резюме' }
    },
    {
        path: '/employer/resumes/:id(\\d+)',
        component: EmployerResumePage,
        meta: { title: 'Резюме' },
        props: true
    },
    {
        path: '/moderation/vacancies',
        name: 'ModerationVacanciesPage',
        component: ModerationVacanciesPage,
        meta: { title: 'Модерация вакансий' }
    },
    {
        path: '/docs',
        component: Docs,
        meta: { title: 'Компоненты' }
    },
    {
        path: '/cards',
        component: Cards,
        meta: { title: 'Карточки' }
    },
    {
        path: '/tt',
        component: Test2,
        meta: { title: 'Карточки' }
    },
    {
        path: '/login',
        component: LoginPage,
        meta: { title: 'Вход' }
    },
    {
        path: '/recover',
        component: RecoverPassowordPage,
        meta: { title: 'Восстановление пароля' }
    },
    {
        path: '/signup',
        component: RegistrationPage,
        meta: { title: 'Регистрация' }
    },
    {
        path: '/signup/employee',
        component: EmployeeRegistrationPage,
        meta: { title: 'Регистрация Соискателя' }
    },
    {
        path: '/signup/employer',
        component: EmployerRegistrationPage,
        meta: { title: 'Регистрация Работодателя' }
    },

    {
        path: '/reset_password',
        component: ResetPasswordPage,
        meta: { title: 'Установка нового пароля' }
    },
    {
        path: '/accountant/lk/orders',
        component: AccountantLkMain,
        meta: { title: 'Бухгалтер' }
    },
    {
        path: '/accountant/lk/orders/:id(\\d+)',
        component: AccountantLkOrder,
        meta: { title: 'Бухгалтер' }
    },


]
const router = createRouter({
    history: createWebHistory(),
    routes,
})


// Устанавливаем заголовок при каждом переходе
router.afterEach((to) => {
    document.title = to.meta.title || 'Умолчание';
});


router.beforeEach((to, from) => {
    const authStore = useAuthStore();
    authStore.checkIsAuthenticated(); // Вызов метода при смене маршрута
});

export default router