<template>
  <!-- Попапы -->
  <InvitationPopup v-if="popupsStore.invitation.visible" v-model="popupsStore.invitation.visible"></InvitationPopup>
  <ResponsePopup v-if="popupsStore.response.visible" v-model="popupsStore.response.visible"></ResponsePopup>
  <AuthorizeRequiredPopup v-if="popupsStore.authorize.visible" v-model="popupsStore.authorize.visible"></AuthorizeRequiredPopup>
  <LogoutPopup v-if="popupsStore.logout.visible" v-model="popupsStore.logout.visible"></LogoutPopup>
  <InfoPopup v-if="popupsStore.info.visible" v-model="popupsStore.info.visible"></InfoPopup>
  <ModeratorBanPopup v-if="popupsStore.moderationBan.visible"  v-model="popupsStore.moderationBan.visible"></ModeratorBanPopup>

  <!-- Выпадашки с действиями -->
  <FiltersSheet v-if="actionSheets.filters.visible" v-model="actionSheets.filters.visible"></FiltersSheet>
  <OrderingSheet v-if="actionSheets.ordering.visible" v-model="actionSheets.ordering.visible"></OrderingSheet>
  <router-view v-if="loaded"></router-view>
</template>

<script setup>
import {useAuthStore} from "@/stores/authStore";
import {onBeforeMount, ref} from "vue";
import Popup from "@/components/ui/Popup";
import {usePopupsStore} from "@/stores/popupsStore";
import InvitationPopup from "@/components/popups/InvitationPopup";
import ResponsePopup from "@/components/popups/ResponsePopup";
import AuthorizeRequiredPopup from "./components/popups/AuthorizeRequiredPopup.vue";
import FiltersSheet from "@/components/sheets/FiltersSheet";
import {useActionSheetsStore} from "@/stores/actionSheets";
import OrderingSheet from "@/components/sheets/OrderingSheet";
import LogoutPopup from "@/components/popups/LogoutPopup";
import InfoPopup from "@/components/popups/InfoPopup";
import ModeratorBanPopup from "./components/popups/ModeratorBanPopup.vue";

const authStore = useAuthStore()
const popupsStore = usePopupsStore()
const loaded = ref(false)
const actionSheets = useActionSheetsStore()

onBeforeMount(async () => {
  if (authStore.checkIsAuthenticated()) {
    await Promise.all([authStore.getMe()])
    loaded.value = true
  } else {
    loaded.value = true
  }
})

</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=Formular:wght@100;200;300;400;500;600;700;800;900&display=swap"); */


@font-face {
  font-family: 'Formular';
  src: url('@/assets/fonts/Formular-Black.ttf') format('truetype');
  font-weight: 900; /* Черный */
  font-style: normal;
}

@font-face {
  font-family: 'Formular';
  src: url('@/assets/fonts/Formular-Bold.ttf') format('truetype');
  font-weight: 700; /* Жирный */
  font-style: normal;
}

@font-face {
  font-family: 'Formular';
  src: url('@/assets/fonts/Formular-Medium.ttf') format('truetype');
  font-weight: 500; /* Средний */
  font-style: normal;
}

@font-face {
  font-family: 'Formular';
  src: url('@/assets/fonts/Formular-Light.ttf') format('truetype');
  font-weight: 300; /* Легкий */
  font-style: normal;
}

@font-face {
  font-family: 'Formular';
  src: url('@/assets/fonts/Formular.ttf') format('truetype');
  font-weight: 400; /* Обычный */
  font-style: normal;
}


html, body {
  padding: 0;
  margin: 0;
  /*height: 100%;*/
  height: 100vh;
  /*overflow: auto;*/
  overflow: hidden;
  /*overflow: hidden;*/
}

body {
  font-family: 'Formular', 'sans-serif';
  color: #1F1E1E;
  touch-action: none;
}

#app {
  height: 100%;
  overflow: auto;
}


.nav-item {


  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;

  color: #888888;
  cursor: pointer;
}

.nav-item[active] {


  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-decoration-line: underline;

  color: #2894FF;
}
</style>

