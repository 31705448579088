<template>
  <div class="fixed top-0 w-full z-40 max-h-full  flex flex-col h-full pointer-events-none overflow-hidden">

    <div v-if="isMobile"
         class="pointer-events-auto flex flex-row bg-[#0A5CD5] h-[56px] pr-[1rem] pl-[1rem]  justify-between items-center">
      <router-link to="/">
        <img src="./../../assets/images/logo.webp" class="w-[108px] h-[44px] object-cover"/>
      </router-link>
      <div class="flex flex-row items-center">
        <Icon name="lupa" class="mr-[2rem]"></Icon>
        <Icon @click="toggleMenu" name="mobile-menu"></Icon>
      </div>


    </div>
    <div v-else class="pointer-events-auto flex flex-row bg-[#0A5CD5] h-[64px] justify-center items-center">
      <div class="flex flex-row justify-between items-center w-[992px]">
        <router-link to="/">
          <img src="./../../assets/images/logo.webp" class="w-[108px] h-[44px] object-cover"/>
        </router-link>

        <div class="flex flex-row gap-x-[24px]">
          <router-link to="/employee/vacancies" @click="sendMetrick('EMPLOYEE-HEADER')">
            <div class="header-menu-item">
              Соискателям
            </div>
          </router-link>

          <router-link to="/employer/resumes" @click="sendMetrick('EMPLOYER-HEADER')">
            <div class="header-menu-item">
              Работодателям
            </div>
          </router-link>

          <router-link to="/">
            <div class="header-menu-item" @click="sendMetrick('STUDENTS-HEADER')">
              Студентам
            </div>
          </router-link>

          <router-link to="/education/articles" @click="sendMetrick('EDUCATION-HEADER')">
            <div class="header-menu-item">
              Обучающая платформа
              <div v-if="!isMobile" class="fixed top-[75px] ml-[165px]">
                <Tooltip v-if="showEmployeeTooltip" type="employee"></Tooltip>
                <Tooltip v-if="showEmployerTooltip" type="employer"></Tooltip>
              </div>
            </div>
          </router-link>


        </div>

        <div v-if="$authStore.isAuthenticated" class="flex flex-row gap-[10px]">
          <div v-if="$authStore.role === 'employee'">
            <router-link :to="{ path: '/employee/lk/main' }">
              <img v-if="$authStore.me.employer_profile?.avatar || $authStore.me.avatar" :src="`${fetch.baseURL}/files/${$authStore.me.employer_profile?.avatar || $authStore.me.avatar}/download`" class="w-[44px] h-[44px] rounded-[22px]">
              <Icon v-else name="employee-icon"></Icon>
            </router-link>
          </div>
          <div v-else-if="$authStore.role === 'employer'">
            <router-link :to="{ path: '/employer/lk/main' }">
              <img v-if="$authStore.me.employer_profile?.avatar || $authStore.me.avatar" :src="`${fetch.baseURL}/files/${$authStore.me.employer_profile?.avatar || $authStore.me.avatar}/download`" class="w-[44px] h-[44px] rounded-[22px]">
              <Icon v-else name="employer-icon"></Icon>
            </router-link>
          </div>
          <div v-else-if="$authStore.role === 'accountant'">
            <router-link :to="{ path: '/accountant/lk/orders' }">
              <Icon name="employer-icon"></Icon>
            </router-link>
          </div>
          <div v-else-if="$authStore.role === 'moderator'">
            <router-link :to="{ path: '/moderator/vacancies' }">
              <Icon name="employer-icon"></Icon>
            </router-link>
          </div>


        </div>


        <div v-else class="flex flex-row">


          <router-link to="/login" class="flex flex-row">
            <Icon @mouseenter="handleMouseEnter('employee')" @mouseleave="handleMouseLeave('employee')"
                  name="employee-icon"></Icon>
            <div class="mr-[12px]"></div>
            <Icon @mouseenter="handleMouseEnter('employer')" @mouseleave="handleMouseLeave('employer')"
                  name="employer-icon"></Icon>

          </router-link>

          <!-- <Tooltip type="employee"></Tooltip>
          <Tooltip type="employer"></Tooltip> -->
        </div>

      </div>


    </div>
    <div v-if="menu"
         class="flex flex-col w-full h-[550px] bg-white items-center rounded-b-[16px] pointer-events-auto z-20 ">
      <div class="h-[73px] w-[90%] flex flex-row items-end justify-start">
        <Icon class="" name="man-icon"></Icon>
        <div class="w-full text-center text-[20px] font-bold">Пользователь</div>
        <div @click="toggleMenu" class="mb-[1.7rem]">
          <Icon name="kross"></Icon>
        </div>
      </div>
      <div class="flex flex-col w-[90%] items-start mt-[32px] mb-[36px]">
        <div @click="router.push('/employee/vacancies') & sendMetrick('EMPLOYEE-HEADER')" class="text-[20px] mb-[28px] leading-[40px]">Соискателям</div>
        <div @click="router.push('/employer/resumes') & sendMetrick('EMPLOYER-HEADER')" class="text-[20px] mb-[28px] leading-[40px]">Работодателям</div>
        <div @click="router.push('/') & sendMetrick('STUDENTS-HEADER')" class="text-[20px] mb-[28px] leading-[40px]">Студентам</div>
        <div @click="router.push('/education/articles') & sendMetrick('EDUCATION-HEADER')" class="text-[20px] mb-[28px] leading-[40px]">Обучающая платформа</div>
      </div>
      <div v-if="!$authStore.isAuthenticated" class="flex flex-col w-[90%] gap-y-[16px] mb-[29px]">
        <ButtonNew @click="router.push('/login') & sendMetrick('LOGIN-AS-EMPLOYEE-HEADER')" type="stroke">Войти как соискатель</ButtonNew>
        <ButtonNew @click="router.push('/login') & sendMetrick('LOGIN-AS-EMPLOYER-HEADER')">Войти как работодатель</ButtonNew>
      </div>
      <div v-else class="flex flex-col w-[90%] gap-y-[16px] mb-[29px]">
        <ButtonNew type="stroke" @click="toAccount">Личный кабинет</ButtonNew>

        <ButtonNew @click="$authStore.logout()" >Выйти</ButtonNew>
      </div>


    </div>
    <div v-if="menu" class="absolute inset-0 bg-opacity-20 bg-[#3D7CD1] overflow-hidden pointer-events-auto"></div>


  </div>

</template>

<script setup>
import {computed} from 'vue';
import Icon from './Icon.vue';
import {defineProps, defineEmits, ref, watch, onUnmounted, onMounted} from 'vue';
import {useRouter, useRoute} from 'vue-router'
import Button from "@/components/ui/Button";
import ButtonNew from './ButtonNew.vue';
import {useAuthStore} from "@/stores/authStore";
import Tooltip from './Tooltip.vue';
import {useFetch} from "@/composables/useFetch";

import { sendMetrick } from '@/composables/utils';

const authStore = useAuthStore()

const showEmployeeTooltip = ref(false)
const showEmployerTooltip = ref(false)
const router = useRouter()

const fetch = useFetch()

const handleMouseEnter = (type) => {
  if (type === 'employee') {
    showEmployeeTooltip.value = true
  } else if (type === 'employer') {
    showEmployerTooltip.value = true
  }
}

const toAccount = () => {
  if (authStore.me.role === 'employer') {
    router.push('/employee/lk/main')
  }
  else if (authStore.me.role === 'employee') {
    router.push('/employee/lk/main')
  }
}

const handleMouseLeave = (type) => {
  if (type === 'employee') {
    showEmployeeTooltip.value = false
  } else if (type === 'employer') {
    showEmployerTooltip.value = false
  }
}

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  const app = document.getElementById('app')
  app.style.overflow = 'auto'
  window.addEventListener('resize', updateIsMobile);
});


onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});

const standardClass = "absolute top-0 w-full z-10 mb-15 ";
const props = defineProps({
  class: {
    type: String,
    default: ''
  },
});

const computedClasses = computed(() => {
  return standardClass + props.class;
});

const menu = ref(false);
const emit = defineEmits(['toggleScroll', 'showMenu', 'hideMenu']);
const toggleMenu = () => {

  const app = document.getElementById('app')
  // emit('toggleScroll')
  menu.value = !menu.value;
  if (menu.value) {
    // document.body.style.overflow = 'hidden';
    app.style.overflow = 'hidden'
    // emit('showMenu')
    // document.documentElement.style.overflow = 'hidden';
  } else {
    // document.body.style.overflow = 'auto';
    app.style.overflow = 'auto'
    // emit('hideMenu')
    // document.documentElement.style.overflow = 'auto';
  }

};

const run = () => {

  const app = document.getElementById('app')
  app.style.overflow = 'auto'
};

</script>

<style scoped>
.header-menu-item {
  @apply text-white font-bold text-[1rem] md:text-[16px] leading-[1.1rem] md:leading-[19.6px] cursor-pointer;
}
</style>