<template>
  <LKLayout>
    <div>
      <div class="flex gap-[32px] flex-col md:flex-row">
        <div>
          <div class="flex flex-row">
            <UIFileUploader v-model="authStore.me.avatar">
              <template #default="data">
                <sheet class="md:!w-[231px] md:!h-[308px] !w-[284px] !h-[200px] relative cursor-pointer overflow-hidden" @click="trigger(data)">
                  <template v-if="data.data.imageUrl">
                    <img :src="data.data.imageUrl" class="w-full h-full object-cover absolute left-0 top-0">
                    <div
                      class="absolute bottom-0 left-0 w-full h-[46px] text-center bg-black bg-opacity-20 backdrop-blur-[5px] flex gap-[8px] items-center justify-center">
                      <div class="text-white text-base leading-5 font-normal">Редактировать</div>
                      <div>
                        <UIIcon size="24" name="camera" color="white"></UIIcon>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <img src="/images/camera.svg" class="absolute top-[86px] left-[66px]">
                    <div class="absolute bottom-0 mb-[34px] text-center">
                      <div class="text-[#0A5CD5] font-light text-[0.75rem] leading-[0.9375rem]">Загрузите свою
                        фотографию
                      </div>
                      <div class="mt-[6px] text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">Формат PNG,
                        JPG.
                        Вес до 2 Mb.
                      </div>
                    </div>
                  </template>

                </sheet>
              </template>

            </UIFileUploader>
          </div>


        </div>
        <div>
          <div :class="[
                mobile.isMobile.value ? 'font-medium text-[18px] leading-[22px] text-[#0A5CD5]' : 'font-medium text-[2.25rem] leading-[2.75rem] text-black'
              ]">
            {{ $authStore.me.last_name + ' ' + $authStore.me.first_name }}
          </div>
          <div
            class="md:mt-[24px] mt-[8px] flex md:gap-[16px] gap-[12px] items-center">
            <UIIcon size="16" name="geoAlt" color="#1F1E1E"></UIIcon>
            <div :class="[
                mobile.isMobile.value ? 'text-[#1F1E1E] text-[14px] leading-[17.12px]' : 'text-[#1F1E1E] text-[1.25rem] leading-[1.5rem] font-normal'
              ]">{{ $authStore.me.city_of_residence ? $authStore.me.city_of_residence : 'Не указано' }}</div>
            
          </div>

          <div class="md:mt-[56px] mt-[24px]">
            <div class="h-[40px] flex items-center gap-[24px]">
              <UIIcon size="24" color="#0A5CD5" name="envelope"></UIIcon>
              <div>{{ $authStore.me.email ? $authStore.me.email : 'Не указано' }}</div>
            </div>
            <div class="h-[40px] flex items-center gap-[24px]">
              <UIIcon size="24" color="#0A5CD5" name="telephone"></UIIcon>
              <div>{{ $authStore.me.phone_number ? $authStore.me.phone_number : 'Не указано' }}</div>
            </div>
            <div class="h-[40px] flex items-center gap-[24px]">
              <UIIcon size="24" :color="undefined" name="telegram"></UIIcon>
              <div class="text-[#7B7A7A] text-[1rem] leading-[1.25rem] font-normal">{{ authStore.me.telegram_contact ?
                $authStore.me.telegram_contact : 'Не указано' }}</div>
            </div>
            <div class="h-[40px] flex items-center gap-[24px]">
              <UIIcon size="24" :color="undefined" name="whatsApp"></UIIcon>
              <div class="text-[#7B7A7A] text-[1rem] leading-[1.25rem] font-normal">{{ authStore.me.whatsapp_contact ?
                $authStore.me.whatsapp_contact : 'Не указано' }}</div>
            </div>
          </div>
        </div>
      </div>


      <div class="md:mt-[32px] mt-[16px] flex items-start gap-[12px]">
        <template v-if="authStore.me.avatar">
          <UIIcon size="24" name="checkCircle"></UIIcon>
          <div class="max-w-[450px] font-light text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] mr-5">Вы заполнили все
            контактные данные. Откликайтесь на вакансии и отслеживайте свои отклики и приглашения в меню.</div>
        </template>
        <template v-else>
          <UIIcon size="24" name="exclamationDiamond"></UIIcon>
          <div class="max-w-[450px] font-light text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] mr-5">Загрузите свое фото,
            так ваше резюме привлечет больше внимания и укажите дополнительные данные, что бы работодатель быстрее
            связался с вами</div>
        </template>


      </div>

      <div class="md:mt-[76px] mt-[40px] flex justify-end">
        <div class="w-[270px] md:w-[290px]">
          <ButtonNew type="stroke" @click="$router.push('/employee/account/settings?type=personalData') & sendMetrick('EMPLOYEE-LK-EDIT')">Редактировать данные</ButtonNew>

        </div>
      </div>
    </div>

  </LKLayout>
</template>

<script setup>

import LKLayout from "@/layouts/LKLayout";
import ButtonNew from "@/components/ui/ButtonNew";
import Sheet from "@/components/cards/Sheet";
import UIIcon from "@/components/ui/UIIcon";
import UIFileUploader from "@/components/ui/UIFileUploader";
import { ref, watch } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useMobile } from "@/composables/useMobile";
import { sendMetrick } from "@/composables/utils";

const mobile = useMobile()

const authStore = useAuthStore()




watch(() => authStore.me.avatar, (newValue, oldValue) => {
  authStore.updateMe({
    ...authStore.me,
  })
})


const trigger = (data) => {
  data.data.triggerFileInput()
  // data.triggerFileInput
}
</script>

<style scoped></style>