import { ofetch } from "ofetch";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { ref } from "vue";


export const useFetch = (router) => {
  const route = useRoute();
  if (!router) {
    const router = useRouter();
  }
 
  // const route = useRoute();
  // const router = useRouter();
  // const baseURL = "http://localhost:8000/api"; // Убедитесь, что указаны правильные адреса
  // const baseURL = "http://80.90.188.22:8003/api"; // Убедитесь, что указаны правильные адреса
  const domain = window.location.hostname;
  const baseURLs = {
    'waterjob.ru': "https://waterjob.ru/api",
    'waterjobs.katskov.tech': "https://waterjobs.katskov.tech/api",
    'localhost': "http://localhost:8000/api",
    // 'localhost:8080': "http://80.90.188.22:8003/api",
    // 'localhost': "https://waterjob.ru/api",
    // 'localhost': "http://localhost:8000/api",
    // 'localhost': 'http://cloud.katskov.tech:10081/api',

    '127.0.0.1': "http://localhost:8000/api",

    '80.90.188.22': "http://80.90.188.22:8003/api",
    'cloud.katskov.tech:10081': 'http://cloud.katskov.tech:10081/api',
    'cloud.katskov.tech': 'http://cloud.katskov.tech:10081/api',
  }
  const baseURL = baseURLs[domain]
  // const baseURL = "http://waterjobs.katskov.tech/api"; // Убедитесь, что указаны правильные адреса
  // const baseURL = "http://localhost:8000/api"; // Убедитесь, что указаны правильные адреса

  async function __request(url, options, auth) {

    const headers = {
      ...options?.headers,
    };
    if (auth === true) {
      const token = localStorage.getItem("store.auth.token");
      if (token === null) {

        const router = useRouter();
        // router.push("/login");
      }
      headers.Authorization = `Bearer ${token}`;
    }


    return await ofetch(url, { baseURL, ...options, headers })
      .catch((err) => {
        const statusCode = err?.response?.status;
        //
        // Если код состояния 401 или 403, редиректим на страницу логина
        if (statusCode === 403 || statusCode === 401) {
          // router.push("/login");
        }

        // Возвращаем ошибку
        return err
        // return {status: statusCode, data: err?.response?._data};
      });
  }

  const authRequest = async (url, options) => {
    return await __request(url, options, true);
  };

  const anonRequest = async (url, options) => {
    return await __request(url, options, false);
  };

  return {
    authRequest,
    anonRequest,
    baseURL,
  };
};
