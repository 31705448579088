<template>
  <Card class="md:min-w-[486px] bg-white cursor-pointer" @click="router.push(`/employer/resumes/${resume.id}/`)">
    <template #header>
      <div class="flex flex-col md:mb-[16px] w-full">
        <div class="text-[1.5rem] leading-[1.8125rem] text-[#0A5CD5] ">{{ resume.speciality_name }}</div>
        <div>от {{ formatPrice(resume.salary_expectations) }} рублей</div>

        <div class="mt-[16px]">
          <div class="text-[#1F1E1E] font-bold text-[1rem] leading-[1.25rem] ">{{ resume.city_of_residence }}</div>
          <div>{{ formatAge(resume.full_years) }}</div>
        </div>

        <div class="mt-[16px]">
          <div class="flex justify-between items-center w-full">
            <div class="text-[#1F1E1E] font-bold text-[1rem] leading-[1.25rem]">Опыт работы</div>
            <div class="text-green-500">{{ formatAge(resume.work_experience) }}</div>
          </div>
          <div v-if="resume.last_job_info" class="flex flex-col md:flex-row md:justify-between md:items-center w-full">
            <div>Последнее место работы</div>
            <div> {{ resume.last_job_info.company_name }}</div>
          </div>
          <div v-else class="mt-6"></div>

        </div>
        <!--        <div v-if="(priceFrom && priceTo) && (priceFrom !== priceTo)"-->
        <!--             class="header-price">От {{ formatPrice(priceFrom)-->
        <!--          }} {{ currencyName }} до {{ formatPrice(priceTo) }} {{ currencyName }}</div>-->
        <!--        <div v-else-if="(priceFrom && priceTo) && (priceFrom === priceTo)"-->
        <!--             class="header-price">{{ formatPrice(priceFrom) }}-->
        <!--          {{ currencyName }}</div>-->
        <!--        <div v-else-if="(priceFrom)" class="header-price">От {{-->
        <!--            formatPrice(priceFrom) }} {{ currencyName }}</div>-->
        <!--        <div v-else-if="(priceTo)" class="header-price">От {{-->
        <!--            formatPrice(priceTo) }} {{ currencyName }}</div>-->
        <!--        <div v-else class="md:mb-[20px] mb-[1.25rem]"></div>-->
      </div>

    </template>
    <template #sub-action>
      <div>
        <BoolIcon name="bookmark-gold" :checked="checked" @click.stop="checked = !checked"></BoolIcon>
      </div>
    </template>

    <template #body></template>
    <!--    <template #body>-->
    <!--      <div class="flex flex-col md:mb-[16px] mb-[0.75rem]">-->
    <!--        <div v-if="city" class="md:text-[16px] md:leading-[19.6px] md:mb-[4px] text-[1rem] leading-[1.2rem] font-bold">{{ city }}</div>-->
    <!--        <div v-else class="md:mb-[23.6px]"></div>-->
    <!--        <div v-if="experience" class="md:text-[16px] md:leading-[19.6px] md:font-normal font-light text-[0.75rem] leading-[0.86rem] text-[#01B00A]">{{ formatexperienceForVacancy(experience) }}</div>-->
    <!--        <div v-else class="md:mb-[19.6px]"></div>-->
    <!--      </div>-->
    <!--    </template>-->
    <template #footer>
      <div></div>
      <!--      <div class="font-light text-[#7B7A7A] md:text-[12px] md:leading-[14.7px] text-[0.75rem] leading-[0.86rem]">{{ formatDateTime(publicationTime) }}</div>-->
    </template>
    <template #action>
      <div class="flex justify-between items-end w-full">
        <div class="ext-[0.75rem] leading-[0.9375rem]  text-[#7B7A7A]">
          {{ formatDateTime(resume.updated_at) }}
        </div>
        <div class="md:w-[149px] w-[9.3rem]">

          <ButtonNew @click.stop="handleInvintationClick()">Пригласить</ButtonNew>
        </div>
      </div>

    </template>
  </Card>
</template>

<script setup>

import {defineProps, ref} from "vue";
import Card from "@/components/cards/Card";
import BoolIcon from "@/components/ui/BoolIcon";
import {formatDateTime, formatPrice, formatAge} from "@/composables/utils";
import ButtonNew from "@/components/ui/ButtonNew";
import {usePopupsStore} from "@/stores/popupsStore";
import {useRouter, useRoute} from "vue-router";
import {useAuthStore} from "@/stores/authStore";
import {useFetch} from "@/composables/useFetch";

const route = useRoute();


const checked = ref(false)

const popupsStore = usePopupsStore()

const handleInvintationClick = () => {
    if(route.path === '/employe/resumes') {
        sendMetrick('EMPLOYER-PAGE-RESUME-INVINTATION')
    }
    popupsStore.openInvitationPopup(props.resume.id, router)
}

const props = defineProps({
  resume: {
    type: Object,
    default: null,
  },

});

const router = useRouter()
const authStore = useAuthStore()
const fetch = useFetch()
import {useToast} from 'vue-toast-notification';

const toast = useToast()


</script>

<style scoped>

</style>