<template>
    <Sheet class="w-full p-[16px] md:p-[56px]">
        <div class="flex flex-col md:flex-row  justify-between">
            <div class="max-w-[60%]">
                <div class="flex flex-col md:gap-[9px]">
                    <div
                        class="font-bold text-[1.25rem] md:text-[2.25rem] leading-[1.5rem] md:leading-[2.75rem] text-blue-700 md:text-[#0A5CD5]">
                        {{
                            vacancy.name
                        }}
                    </div>

                    <div
                        class="font-normal text-[0.875rem] md:text-[1.25rem] leading-[1.0625rem] md:leading-[1.5rem] text-black   md:text-[#1F1E1E]">
                        {{ vacancy?.employer?.company_name }}
                    </div>

                    <div
                        class="font-medium text-[1.125rem] md:text-[1.5rem] leading-[1.375rem] md:leading-[1.8125rem] text-[#1F1E1E] flex justify-between">
                        <div>{{ vacancy?.city }}</div>
                        <div class="md:hidden">от {{ formatPrice(vacancy.price_from) }}</div>

                    </div>
                </div>


                <div class="flex flex-col gap-[12px] mt-[41px] md:min-w-[426px] md:max-w-[600px]">
                    <div class=" flex justify-between">
                        <div class=" font-normal text-base leading-[1.25] text-[#1F1E1E] ">Требуемый опыт</div>
                        <div class="text-[1rem] leading-[1.25rem] text-[#01B00A] ">
                            {{ formatexperienceForVacancy(vacancy.experience) }}
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <div class="font-normal text-base leading-[1.25] text-[#1F1E1E] ">Тип занятости</div>
                        <div class="font-normal text-[1rem] leading-[1.25rem] text-[#1F1E1E]">
                            {{ humanizeEmploymentType(vacancy.employment_type) }}
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <div class=" font-normal text-base leading-[1.25] text-[#1F1E1E] ">График работы</div>
                        <div class="font-normal text-[1rem] leading-[1.25rem] text-[#1F1E1E]">
                            {{ humanizeEmploymentShedule(vacancy.employment_schedule) }}
                        </div>
                    </div>

                    <div class=" flex justify-between">
                        <div class=" font-normal text-base leading-[1.25] text-[#1F1E1E] ">Образование</div>
                        <div class="font-normal text-[1rem] leading-[1.25rem] text-[#1F1E1E]">
                            {{ humanizeEducationLevel(vacancy.education) }}
                        </div>
                    </div>


                </div>

            </div>

            <div class="flex flex-col">
                <div class="hidden md:block text-black font-bold text-[1.875rem] leading-[2.3125rem]">от
                    {{ formatPrice(vacancy.price_from) }}
                </div>
                <div v-if="vacancy?.employer?.avatar" class="mt-[52px]">
                    <img class="max-w-[214px]" :src="`${baseURL}/files/${vacancy?.employer?.avatar}/download`">
                </div>
                <!-- <div v-else class="w-[214px] h-[54px] bg-[#FFFAFA]">
                </div> -->
                <div v-else
                    class="w-full mt-10 h-[91px] flex flex-row justify-center items-center border-[1px] border-[#B7B7B7] rounded-[15px]">
                    <UIIcon size="50" name="camera" color="#B7B7B7"></UIIcon>
                </div>


                <!-- <div class="flex items-center mt-[13px] gap-[12px]">
                  <div class="font-bold text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">4.5</div>
                  <Grade model-value="4.5"></Grade>
                </div> -->

                <div v-if="$authStore.isAuthenticated" class=" flex-col mt-[21px] md:mt-[56px]">
                    <div class="flex items-center gap-[12px] px-[10px]">
                        <img src="/icons/envelope-12-blue.svg" class="w-[24px] h-[24px]">

                        <div v-if="vacancy?.employer?.email"
                            class="flex items-center h-[33px] md:font-light text-[0.875rem] md:text-base leading-[1.0625rem] md:leading-[1.25rem] text-[#1F1E1E]">
                            {{ vacancy?.employer?.email }}
                        </div>
                    </div>
                    <div class="flex items-center gap-[12px] px-[10px]">
                        <img src="/icons/telephone-12-blue.svg" class="w-[24px] h-[24px]">
                        <div v-if="vacancy?.employer?.phone"
                            class="flex flex-grow items-center h-[33px] md:font-light text-[0.875rem] md:text-base leading-[1.0625rem] md:leading-[1.25rem] text-[#1F1E1E] justify-between">
                            {{ vacancy?.employer?.phone }}
                            <div class="md:hidden  text-blue-600 font-medium text-[0.875rem] leading-[1.0625rem] underline cursor-pointer"
                                @click="$router.push(`/employee/vacancies?profession=${vacancy?.employer?.company_name}`)">
                                Все
                                вакансии
                            </div>
                        </div>
                    </div>

                    <div v-if="$authStore.isAuthenticated"
                        class=" mt-[40px] md:mt-[50px] flex flex-col gap-[21px] items-center">
                        <slot name="under-logo">
                            <ButtonNew @click.stop="popupsStore.openResponsePopup(vacancy.id, $router)">Откликнуться
                            </ButtonNew>

                            <div class="hidden md:block font-medium text-[1rem] leading-[1.25rem] underline text-[#0A5CD5] cursor-pointer"
                                @click="$router.push(`/employee/vacancies?profession=${vacancy?.employer?.company_name}`)">
                                Все
                                вакансии компании
                            </div>
                        </slot>


                    </div>

                </div>
            </div>
        </div>

        <div class="mt-[40px] md:mt-[16px]">

            <div
                class="font-medium text-[1.125rem] md:text-[1.875rem] leading-[1.375rem] md:leading-[2.3125rem] text-[#1F1E1E] md:text-[#1F1E1E]">
                Описание вакансии</div>

            <div
                class="mt-[32px] text-black font-normal text-sm md:text-base leading-4 md:leading-5 whitespace-pre-line">
                {{ vacancy.full_description }}
            </div>
        </div>


        <div class="mt-[32px] flex flex-col gap-[21px] items-center  md:items-end">
            <slot name="down-right-card">
                <ButtonNew @click.stop="popupsStore.openResponsePopup(vacancy.id, $router)"
                    class="w-full md:w-fit md:p-[14px_69px]">Откликнуться
                </ButtonNew>
            </slot>

        </div>
    </Sheet>
</template>
<script setup>
import { ref } from 'vue';
import { useFetch } from '@/composables/useFetch';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import Sheet from "@/components/cards/Sheet";
import {
    formatexperienceForVacancy, formatPrice,
    humanizeEducationLevel,
    humanizeEmploymentShedule,
    humanizeEmploymentType
} from "@/composables/utils";
import Grade from "@/components/ui/Grade";
import ButtonNew from "@/components/ui/ButtonNew";
import { usePopupsStore } from "@/stores/popupsStore";
import UIIcon from '@/components/ui/UIIcon.vue';

const route = useRoute();

const { anonRequest, authRequest, baseURL } = useFetch();

const popupsStore = usePopupsStore()

const props = defineProps({
    vacancy: {
        default: null
    }
});

</script>